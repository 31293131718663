import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Menu } from '../../interfaces/menu.interface';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { ContentService } from '../../services/vendor-config-service/content-provider.service';
import { Product } from '../../interfaces/product.interface';
import { Location } from '../../interfaces/location.interface';
import { OptionGroup } from '../../interfaces/option-group.interface';
import { Ingredient } from './interfaces/ingredient.interface';
import { ModifierGroup } from './interfaces/modifier-group.interface';
import { Order } from '../../interfaces/order.interface';
import { OrderPayment } from '../itwercs/interfaces/order-payment.interface';
import { Upsells } from '../../interfaces/upsells.interface';
import { Select } from '@ngxs/store';
import { Branding } from './interfaces/branding.interface';
import { Option } from '../../interfaces/option.interface';

@Injectable({
  providedIn: 'root',
})
export class ImageContentService {
  @Select(state => state.app.branding) branding$: Observable<Branding>;

  constructor(private contentService: ContentService) {}

  getLocationWithMapIconURL(loc: Location): Observable<Location> {
    return this.contentService.getService().pipe(
      switchMap(service => {
        return this.branding$.pipe(
          filter(b => b !== null),
          take(1),
          switchMap(branding => {
            if (branding && branding.location_pin_icon) {
              loc.mapIconURL = branding.location_pin_icon.data.full_url;
            }
            return service.getLocations().pipe(
              map(locations => {
                const location = locations.find(cmsLoc => cmsLoc.menu_id === loc.locationID);
                if (location) {
                  if (location.concept_primary_color && location.concept_secondary_color) {
                    loc.conceptPrimaryColor = location.concept_primary_color;
                    loc.conceptSecondaryColor = location.concept_secondary_color;
                  }
                  loc.mapIconURL = location.concept_map_pin
                    ? location.concept_map_pin.data.full_url
                    : branding.location_pin_icon?.data.full_url;
                  const showConceptLogo = locations.some(l => l.concept_logo);
                  if (showConceptLogo) {
                    loc.conceptLogoURL = location.concept_logo ? location.concept_logo.data.full_url : branding.main_logo?.data.full_url;
                  }
                  loc.slugURL = location.location_slug;
                  loc.externalLink = location.external_link;
                  loc.cateringLink = location.catering_link;
                  loc.isHidden = location.is_hidden;
                  if (location.pickup_instructions) {
                    loc.pickupInstructions = location.pickup_instructions;
                  }
                  if (location.curbside_instructions) {
                    loc.curbsideInstructions = location.curbside_instructions;
                  }
                  if (location.delivery_instructions) {
                    loc.deliveryInstructions = location.delivery_instructions;
                  }
                  if (location.dispatch_instructions) {
                    loc.dispatchInstructions = location.dispatch_instructions;
                  }
                  if (location.tableside_instructions) {
                    loc.tablesideInstructions = location.tableside_instructions;
                  }
                  if (location.drivethru_instructions) {
                    loc.driveThruInstructions = location.drivethru_instructions;
                  }
                }
                return loc;
              }),
              catchError(() => {
                return of(loc);
              })
            );
          })
        );
      })
    );
  }

  getProductWithImages(prod: Product): Observable<Product> {
    const product = JSON.parse(JSON.stringify(prod)) as Product; // Deep clone to avoid mutating the original data

    return this.contentService.getService().pipe(
      switchMap(service => {
        return service.getContentProducts().pipe(
          switchMap(products => {
            const contentProduct = products.find(p => p.name.trim() === product.name.trim());

            // Map content product details to the product object
            if (contentProduct) {
              product.nameSlug = contentProduct.name_slug || product.nameSlug;
              product.seoDescription = contentProduct.seo_description || product.seoDescription;
              product.allergenInfo = contentProduct.allergen_items || product.allergenInfo;
              product.badge_text = contentProduct.badge_text || product.badge_text;
              product.badge_text_color = contentProduct.badge_text_color || product.badge_text_color;
              product.badge_color = contentProduct.badge_color || product.badge_color;

              // Map nutrition info
              if (product.nutritionInfo) {
                product.nutritionInfo.calories = contentProduct.calories || product.nutritionInfo.calories;
                product.nutritionInfo.carbohydrateGrams = contentProduct.carbohydrates || product.nutritionInfo.carbohydrateGrams;
                product.nutritionInfo.cholesterolGrams = contentProduct.cholesterol || product.nutritionInfo.cholesterolGrams;
                product.nutritionInfo.dietaryFiberGrams = contentProduct.dietary_fiber || product.nutritionInfo.dietaryFiberGrams;
                product.nutritionInfo.totalFatGrams = contentProduct.grams_of_fat || product.nutritionInfo.totalFatGrams;
                product.nutritionInfo.proteinGrams = contentProduct.protein || product.nutritionInfo.proteinGrams;
                product.nutritionInfo.saturatedFatGrams = contentProduct.saturated_fats || product.nutritionInfo.saturatedFatGrams;
                product.nutritionInfo.servingSize = contentProduct.serving_size || product.nutritionInfo.servingSize;
                product.nutritionInfo.sodiumGrams = contentProduct.sodium || product.nutritionInfo.sodiumGrams;
                product.nutritionInfo.sugarGrams = contentProduct.sugars || product.nutritionInfo.sugarGrams;
                product.nutritionInfo.transFatGrams = contentProduct.trans_fats || product.nutritionInfo.transFatGrams;
              }

              product.useIngredientImages = contentProduct.use_ingredient_images || product.useIngredientImages;
              product.showAsModal = contentProduct.show_as_modal !== null ? contentProduct.show_as_modal : product.showAsModal;

              // Map images
              if (contentProduct.image && contentProduct.image.data) {
                product.standardImageURL = contentProduct.image.data.full_url || product.standardImageURL;
                const thumbnail = contentProduct.image.data.thumbnails?.find(c => c.width === 600);
                product.thumbnailImageURL = thumbnail ? thumbnail.url : product.thumbnailImageURL;
              }

              product.loyaltyPOSIDs = contentProduct.loyalty_pos_ids || product.loyaltyPOSIDs;

              // Configurations
              if (contentProduct.config_1_name && contentProduct.config_1_ids?.length > 0) {
                product.configurations.push({
                  name: contentProduct.config_1_name,
                  ids: contentProduct.config_1_ids,
                });
              }

              if (contentProduct.config_2_name && contentProduct.config_2_ids?.length > 0) {
                product.configurations.push({
                  name: contentProduct.config_2_name,
                  ids: contentProduct.config_2_ids,
                });
              } else if (product.configurations?.length > 1) {
                product.configurations = product.configurations.slice(0, 1); // Keep only 1 configuration if applicable
              }

              product.hideFromGuests = contentProduct.hide_from_guests || product.hideFromGuests;
            }

            return service.getContentOptions().pipe(
              switchMap(options => {
                return service.getModifierGroups().pipe(
                  map(modGroups => {
                    const hiddenGroupIDs: string[] = [];

                    // Process option groups
                    product.optionGroups.forEach(group => {
                      group = this.getOptionGroupWithImages(group, options, modGroups, product);

                      // In case we need to apply any logic for hidden groups, just track the hidden group IDs
                      // Process them if needed, for now we can keep it simple without extra hidden logic
                    });

                    // Optionally hide option groups if necessary
                    product.optionGroups.forEach(group => {
                      hiddenGroupIDs.forEach((hiddenGroupID: string) => {
                        group = this.hideOptionGroupID(group, hiddenGroupID);
                      });
                    });
                    return product;
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  getOrderItemsWithSlugsandImages(order: Order): Observable<Order> {
    return this.contentService.getService().pipe(
      switchMap(service => {
        return service.getContentProducts().pipe(
          switchMap(products => {
            return service.getContentOptions().pipe(
              switchMap(options => {
                return service.getModifierGroups().pipe(
                  switchMap(modGroups => {
                    return this.getLocationWithMapIconURL(order.location).pipe(
                      map(location => {
                        order.location = location;
                        order.items.forEach(prod => {
                          const contentProduct = products.find(p => p.name.trim() === prod.name.trim());
                          if (contentProduct) {
                            prod.showAsModal = contentProduct.show_as_modal;
                            prod.nameSlug = contentProduct.name_slug;
                            if (contentProduct.image && contentProduct.image.data && contentProduct.image.data.thumbnails) {
                              prod.thumbnailImageURL = contentProduct.image.data.thumbnails.find(c => c.width === 600).url;
                            }
                          }
                          const hiddenGroupIDs: string[] = [];
                          // prod.optionGroups.forEach(group => {
                          // group = this.getOptionGroupWithImages(group, options, modGroups, product);
                          // const customGroup = this.addCustomOptionGroups(group, modGroups, product);
                          // // console.log(customGroup);
                          // if (customGroup) {
                          //   group = customGroup.optionGroup;
                          //   customGroup.hiddenGroups.forEach((hiddenGroupID: string) => {
                          //     hiddenGroupIDs.push(hiddenGroupID);
                          //   });
                          // }
                          // });
                          // prod.optionGroups.forEach(group => {
                          // if (hiddenGroupIDs && hiddenGroupIDs.length > 0) {
                          //   // console.log(hiddenGroupIDs);
                          //   hiddenGroupIDs.forEach((hiddenGroupID: string) => {
                          //     group = this.hideOptionGroupID(group, hiddenGroupID);
                          //   });
                          //   // console.log(group);
                          // }
                          // });
                        });
                        return order;
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  getUpsellsWithImages(upsells: Upsells): Observable<Upsells> {
    return this.contentService.getService().pipe(
      switchMap(cService => {
        return cService.getContentProducts().pipe(
          map(cmsProducts => {
            upsells.items.forEach(prod => {
              const contentProduct = cmsProducts.find(p => this.toSlug(p.name) === this.toSlug(prod.name));
              if (contentProduct && contentProduct.image && contentProduct.image.data && contentProduct.image.data.thumbnails) {
                prod.thumbnailImageURL = contentProduct.image.data.thumbnails.find(c => c.width === 600).url;
              }
            });
            return upsells;
          })
        );
      })
    );
  }

  getMenuWithImages(menu: Menu, sortCategory?: boolean): Observable<Menu> {
    return this.contentService.getService().pipe(
      switchMap(service => {
        return forkJoin({
          categories: service.getContentCategories(),
          products: service.getContentProducts(),
          textFields: service.getTextFields(),
        }).pipe(
          map(data => {
            if (menu.singleUseProducts) {
              if (data.textFields.single_use_category_title) {
                menu.singleUseProducts.name = data.textFields.single_use_category_title;
              }
              if (data.textFields.single_use_category_description) {
                menu.singleUseProducts.description = data.textFields.single_use_category_description;
              }
            }
            menu.categories.forEach(cat => {
              let contentCat = data.categories.find(category => category.name.trim() === cat.name.trim());
              if (contentCat) {
                cat.nameSlug = contentCat.name_slug;
                cat.isHidden = contentCat.hide_category;
                if (contentCat.seo_description) {
                  cat.seoDescription = contentCat.seo_description;
                }
                if (contentCat.badge_text) {
                  cat.badge_text = contentCat.badge_text;
                }
                if (contentCat.badge_color) {
                  cat.badge_color = contentCat.badge_color;
                }
                if (contentCat.badge_text_color) {
                  cat.badge_text_color = contentCat.badge_text_color;
                }
                cat.show_category_banner = contentCat.show_category_banner;
                if (sortCategory) {
                  cat.categoryOrder = contentCat.category_order ? contentCat.category_order : null;
                }
                contentCat = data.categories.find(category => this.toSlug(category.name.trim()) === this.toSlug(cat.name.trim()));
                if (contentCat.image && contentCat.image.data) {
                  cat.thumbnailImageURL = contentCat.image.data.full_url;
                  if (contentCat.image.data.thumbnails) {
                    cat.thumbnailImageURL = contentCat.image.data.thumbnails.find(c => c.width === 600).url;
                  }
                }
                if (contentCat.hide_from_guests) {
                  cat.hideFromGuests = contentCat.hide_from_guests;
                }
              }
              cat.products.forEach(prod => {
                const contentProd = data.products.find(product => product.name.trim() === prod.name.trim());
                if (contentProd) {
                  prod.nameSlug = contentProd.name_slug;
                  prod.loyaltyPOSIDs = contentProd.loyalty_pos_ids;
                  if (contentProd.allergen_items) {
                    prod.allergenInfo = contentProd.allergen_items;
                  }
                  if (contentProd.image && contentProd.image.data) {
                    prod.standardImageURL = contentProd.image.data.full_url;
                    if (contentProd.image.data.thumbnails) {
                      prod.thumbnailImageURL = contentProd.image.data.thumbnails.find(p => p.width === 600).url;
                    }
                  }
                  // tslint:disable-next-line:max-line-length
                  if (
                    contentProd &&
                    contentProd.config_1_name !== null &&
                    contentProd.config_1_ids !== null &&
                    contentProd.config_1_ids.length > 0
                  ) {
                    if (!prod.configurations) {
                      prod.configurations = [];
                    }
                    prod.configurations.push({
                      name: contentProd.config_1_name,
                      ids: contentProd.config_1_ids,
                    });
                  }
                  // tslint:disable-next-line:max-line-length
                  if (
                    contentProd &&
                    contentProd.config_2_name !== null &&
                    contentProd.config_2_ids !== null &&
                    contentProd.config_2_ids.length > 0
                  ) {
                    if (!prod.configurations) {
                      prod.configurations = [];
                    }
                    prod.configurations.push({
                      name: contentProd.config_2_name,
                      ids: contentProd.config_2_ids,
                    });
                  }
                  if (contentProd.ingredient_groups) {
                    prod.ingredientGroups = contentProd.ingredient_groups;
                  }
                  if (contentProd && contentProd.hide_from_guests) {
                    prod.hideFromGuests = contentProd.hide_from_guests;
                  }
                }
                if (contentCat) {
                  prod.isAlcohol = prod.isAlcohol ? prod.isAlcohol : contentCat.is_alcohol;
                }
              });
            });
            if (menu.singleUseProducts) {
              let contentCat = data.categories.find(category => category.name.trim() === menu.singleUseProducts.name.trim());
              if (contentCat) {
                menu.singleUseProducts.nameSlug = contentCat.name_slug;
                menu.singleUseProducts.isHidden = contentCat.hide_category;
                if (contentCat.seo_description) {
                  menu.singleUseProducts.seoDescription = contentCat.seo_description;
                }
                if (sortCategory) {
                  menu.singleUseProducts.categoryOrder = contentCat.category_order ? contentCat.category_order : null;
                }
                contentCat = data.categories.find(
                  category => this.toSlug(category.name.trim()) === this.toSlug(menu.singleUseProducts.name.trim())
                );
                if (contentCat.image && contentCat.image.data) {
                  menu.singleUseProducts.standardImageURL = contentCat.image.data.full_url;
                  if (contentCat.image.data.thumbnails) {
                    menu.singleUseProducts.thumbnailImageURL = contentCat.image.data.thumbnails.find(c => c.width === 600).url;
                  }
                }
                if (contentCat.hide_from_guests) {
                  menu.singleUseProducts.hideFromGuests = contentCat.hide_from_guests;
                }
              }
              menu.singleUseProducts.products.forEach(prod => {
                const contentProd = data.products.find(product => product.name.trim() === prod.name.trim());
                if (contentProd) {
                  prod.nameSlug = contentProd.name_slug;
                  if (contentProd.allergen_items) {
                    prod.allergenInfo = contentProd.allergen_items;
                  }
                  if (contentProd.image && contentProd.image.data) {
                    prod.standardImageURL = contentProd.image.data.full_url;
                    if (contentProd.image.data.thumbnails) {
                      prod.thumbnailImageURL = contentProd.image.data.thumbnails.find(p => p.width === 600).url;
                    }
                  }
                  // tslint:disable-next-line:max-line-length
                  if (
                    contentProd &&
                    contentProd.config_1_name !== null &&
                    contentProd.config_1_ids !== null &&
                    contentProd.config_1_ids.length > 0
                  ) {
                    if (!prod.configurations) {
                      prod.configurations = [];
                    }
                    prod.configurations.push({
                      name: contentProd.config_1_name,
                      ids: contentProd.config_1_ids,
                    });
                  }
                  // tslint:disable-next-line:max-line-length
                  if (
                    contentProd &&
                    contentProd.config_2_name !== null &&
                    contentProd.config_2_ids !== null &&
                    contentProd.config_2_ids.length > 0
                  ) {
                    if (!prod.configurations) {
                      prod.configurations = [];
                    }
                    prod.configurations.push({
                      name: contentProd.config_2_name,
                      ids: contentProd.config_2_ids,
                    });
                  }
                  if (contentProd.ingredient_groups) {
                    prod.ingredientGroups = contentProd.ingredient_groups;
                  }
                  if (contentProd && contentProd.hide_from_guests) {
                    prod.hideFromGuests = contentProd.hide_from_guests;
                  }
                }
                if (contentCat) {
                  prod.isAlcohol = prod.isAlcohol ? prod.isAlcohol : contentCat.is_alcohol;
                }
              });
            }
            return menu;
          })
        );
      })
    );
  }

  getLocationsWithSlug(locations: Location[]): Observable<Location[]> {
    return this.contentService.getService().pipe(
      switchMap(service => {
        return service.getLocations().pipe(
          map(contentLocations => {
            locations.forEach(loc => {
              contentLocations.forEach(conLoc => {
                if (loc.locationID === conLoc.menu_id) {
                  loc.slugURL = conLoc.location_slug;
                }
              });
            });
            return locations;
          })
        );
      })
    );
  }

  getSingleLocationWithSlug(location: Location): Observable<Location> {
    return this.contentService.getService().pipe(
      switchMap(service => {
        return service.getLocations().pipe(
          map(locations => {
            const contentLocation = locations.find(cmsLoc => cmsLoc.menu_id === location.locationID);
            if (contentLocation && location.locationID === contentLocation.menu_id) {
              location.slugURL = contentLocation.location_slug;
              if (contentLocation.concept_primary_color && contentLocation.concept_secondary_color) {
                location.conceptPrimaryColor = contentLocation.concept_primary_color;
                location.conceptSecondaryColor = contentLocation.concept_secondary_color;
              }
            }
            return location;
          }),
          catchError(() => {
            return of(location);
          })
        );
      })
    );
  }

  getDestinationId(order: Order) {
    if (order.handoffType === null) {
      order.handoffType = 5;
    }
    return this.contentService.getService().pipe(
      switchMap(service => {
        return service.getItwercsSettings().pipe(
          map(contentSettings => {
            const handoffs = Object.assign({}, contentSettings.handoff_type);
            return Number(handoffs[String(order.handoffType)]);
          })
        );
      })
    );
  }

  getTenderTypeFromCardBrand(payment: OrderPayment) {
    return this.contentService.getService().pipe(
      switchMap(service => {
        return service.getItwercsSettings().pipe(
          map(contentSettings => {
            const payments = Object.assign({}, contentSettings.payment_type);
            if (payments.hasOwnProperty(String(payment.TenderId))) {
              payment.TenderId = Number(payments[String(payment.TenderId)]);
            } else {
              payment.TenderId = Number(payments['0']);
            }
            return payment;
          })
        );
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  private getOptionGroupWithImages(
    group: OptionGroup,
    contentOptions: Ingredient[],
    modGroups: ModifierGroup[],
    product: Product
  ): OptionGroup {
    const mod = modGroups.find(op => group.brandOptionGroupID === op.chain_group_id);

    if (mod) {
      group.displayType = mod.group_type === 'button-group' ? 'button-group' : mod.use_ingredient_images ? 'images' : 'simple';
      group.tablesideDisplayType =
        mod.tableside_group_type === 'button-group' ? 'button-group' : mod.use_tableside_images ? 'images' : 'simple';
      group.showPricing = !!mod.show_pricing;
      group.hideOnEdit = mod.hide_on_edit;
    }

    if (this.isHalfAndHalfParent(group)) {
      const originalOptions = group.options;
      // If the group is a half-and-half parent, replace the options array with the combined options
      const newOptions = this.constructNewOptionGroup(
        group.options.find(op => op.metadata.find(m => m.key === 'half-and-half' && m.value === 'left')), // Left
        group.options.find(op => op.metadata.find(m => m.key === 'half-and-half' && m.value === 'whole')), // Whole
        group.options.find(op => op.metadata.find(m => m.key === 'half-and-half' && m.value === 'right')) // Right
      );

      // Replace the original options with the newly constructed options
      group.options = newOptions;
    }

    // Recursively process child option groups if present
    group.options.forEach(op => {
      const contentOption = contentOptions.find(option => this.toSlug(option.name) === this.toSlug(op.name));
      op.isInverted = !!contentOption?.invert_choice;
      if (op.isInverted) {
        // remove No, None, or Without from the option name
        op.name = op.name.replace(/(No |None |Without )/g, '');
      }

      if (contentOption && contentOption.image && contentOption.image.data && !op.standardImageURL) {
        op.standardImageURL = contentOption.image.data.full_url;
        op.thumbnailImageURL = contentOption.image.data.thumbnails?.find(o => o.width === 400)?.url || '';
      }

      if (op.optionGroups) {
        op.optionGroups.forEach(og => {
          og = this.getOptionGroupWithImages(og, contentOptions, modGroups, product);
        });
      }
    });
    return group;
  }

  // Function to check if the given option is a half-and-half parent
  private isHalfAndHalfParent(group: OptionGroup): boolean {
    if (
      group.options.find(op => op.metadata.find(m => m.key === 'half-and-half' && m.value === 'whole')) &&
      group.options.find(op => op.metadata.find(m => m.key === 'half-and-half' && m.value === 'left')) &&
      group.options.find(op => op.metadata.find(m => m.key === 'half-and-half' && m.value === 'right')) &&
      group.options.length === 3
    ) {
      return true;
    }
    return false;
  }
  // Function to construct a new option group combining left, whole, and right
  private constructNewOptionGroup(leftOption: Option, wholeOption: Option, rightOption: Option): Option[] {
    const newOptions: Option[] = [];

    // Iterate over the options under the "Whole" group and create a new set of options
    wholeOption.optionGroups[0].options.forEach((wholeOpt: Option) => {
      const leftMatch = leftOption.optionGroups[0].options.find(op => this.toSlug(op.name) === this.toSlug(wholeOpt.name));
      const rightMatch = rightOption.optionGroups[0].options.find(op => this.toSlug(op.name) === this.toSlug(wholeOpt.name));

      // If both left and right matches are found, create a new combined option
      if (leftMatch && rightMatch) {
        const newOption: Option = {
          optionID: wholeOpt.optionID + '-combined',
          brandOptionID: wholeOpt.brandOptionID,
          name: wholeOpt.name, // Option name remains the same as in the whole group
          nameSlug: this.toSlug(wholeOpt.name),
          addedCents: 0,
          isDefault: false,
          isSelected: false,
          hasChildren: true,
          optionGroups: [
            {
              optionGroupID: wholeOpt.optionID + '-positions',
              brandOptionGroupID: wholeOpt.parentOptionGroupID,
              name: 'Position Selection',
              nameSlug: 'position-selection',
              displayType: 'half-and-half',
              description: `Choose the position for ${wholeOpt.name}`,
              options: [
                { ...leftMatch, name: 'Left: ' + leftMatch.name, additionalOptions: [leftOption] }, // Prefix "Left: " for the left option
                { ...wholeOpt, name: 'Whole: ' + wholeOpt.name, additionalOptions: [wholeOption] }, // Prefix "Whole: " for the whole option
                { ...rightMatch, name: 'Right: ' + rightMatch.name, additionalOptions: [rightOption] }, // Prefix "Right: " for the right option
              ],
              minRequired: 0,
              maxAllowed: 1,
              mandatorySelection: false,
              incrementValue: 1,
              showPricing: true,
              hideCost: true,
              metadata: [],
              minTotalQuantity: 0,
              maxTotalQuantity: 0,
              minChoiceQuantity: 0,
              maxChoiceQuantity: 0,
              hideOnEdit: true,
            },
          ],
          nutritionInfo: wholeOpt.nutritionInfo,
          standardImageURL: wholeOpt.standardImageURL,
          thumbnailImageURL: wholeOpt.thumbnailImageURL,
          isInverted: false,
          whenSelected: undefined,
          modifierCategoryID: 0,
          parentOptionGroupID: wholeOpt.parentOptionGroupID,
          additionalOptions: [],
          selectionTriggers: [],
          hideOnDisplay: false,
          quantity: 1,
        };
        newOptions.push(newOption);
      }
    });

    return newOptions;
  }

  checkOptionGroupID(optionGroup: OptionGroup, optionGroupID: string) {
    // console.log('incoming id:', optionGroupID);
    // console.log(optionGroup.optionGroupID === optionGroupID);
    // console.log('checking id:', optionGroup.optionGroupID);
    if (optionGroup.optionGroupID === optionGroupID) {
      return optionGroup;
    } else {
      let newOptionGroup = null;
      optionGroup.options.forEach((option: Option) => {
        if (!newOptionGroup) {
          newOptionGroup = this.checkOptionGroupIDInOption(option, optionGroupID);
        }
      });
      return newOptionGroup;
    }
  }

  checkOptionGroupIDInOption(option: Option, optionGroupID: string) {
    let optionGroup = null;
    option.optionGroups.forEach((og: OptionGroup) => {
      if (!optionGroup) {
        optionGroup = this.checkOptionGroupID(og, optionGroupID);
      }
    });
    return optionGroup;
  }

  findOptionGroupByBrandID(product: Product, brandOptionGroupID: string): OptionGroup {
    let optionGroup = null;
    product.optionGroups.forEach((og: OptionGroup) => {
      if (!optionGroup) {
        optionGroup = this.checkBrandOptionGroupID(og, brandOptionGroupID);
      }
    });
    return optionGroup;
  }

  checkBrandOptionGroupID(optionGroup: OptionGroup, brandOptionGroupID: string) {
    // console.log('incoming id:', brandOptionGroupID);
    // console.log(optionGroup.brandOptionGroupID === brandOptionGroupID);
    // console.log('checking id:', optionGroup.optionGroupID);
    if (optionGroup.brandOptionGroupID === brandOptionGroupID) {
      return optionGroup;
    } else {
      let newOptionGroup = null;
      optionGroup.options.forEach((option: Option) => {
        if (!newOptionGroup) {
          newOptionGroup = this.checkBrandOptionGroupIDInOption(option, brandOptionGroupID);
        }
      });
      return newOptionGroup;
    }
  }

  checkBrandOptionGroupIDInOption(option: Option, brandOptionGroupID: string) {
    let optionGroup = null;
    option.optionGroups.forEach((og: OptionGroup) => {
      if (!optionGroup) {
        optionGroup = this.checkBrandOptionGroupID(og, brandOptionGroupID);
      }
    });
    return optionGroup;
  }

  // Hide a single option by ID

  hideOptionGroupID(optionGroup: OptionGroup, optionGroupID: string): OptionGroup {
    if (optionGroup.optionGroupID === optionGroupID) {
      optionGroup.hideOnEdit = false;
      return optionGroup;
    } else {
      optionGroup.options.forEach((option: Option) => {
        option = this.hideOptionGroupIDInOption(option, optionGroupID);
      });
      return optionGroup;
    }
  }

  hideOptionGroupIDInOption(option: Option, optionGroupID: string): Option {
    option.optionGroups.forEach((og: OptionGroup) => {
      og = this.hideOptionGroupID(og, optionGroupID);
    });
    return option;
  }

  // Get option chain including parents

  getOptionGroupChainByID(product: Product, option: Option): Option[] {
    const optionChain: Option[] = [];
    product.optionGroups.forEach((og: OptionGroup) => {
      const foundOption = og.options.find((op: Option) => op.optionID === option.optionID);
      if (!foundOption) {
        og.options.forEach((op: Option) => {
          if (op.optionGroups.length > 0) {
            const additionalChainOptions = this.getOptionGroupIDChainInOption(op, option);
            if (additionalChainOptions.length > 0) {
              optionChain.push(op, ...additionalChainOptions);
            }
          }
        });
      } else {
        optionChain.push(foundOption);
      }
    });
    return optionChain;
  }

  getOptionGroupIDChainInOption(parentOption: Option, option: Option): Option[] {
    const optionChain: Option[] = [];
    parentOption.optionGroups.forEach((og: OptionGroup) => {
      const foundOption = og.options.find((op: Option) => op.optionID === option.optionID);
      if (!foundOption) {
        og.options.forEach((op: Option) => {
          if (op.optionGroups.length > 0) {
            const additionalChainOptions = this.getOptionGroupIDChainInOption(op, option);
            if (additionalChainOptions.length > 0) {
              optionChain.push(op, ...additionalChainOptions);
            }
          }
        });
      } else {
        optionChain.push(foundOption);
      }
    });
    return optionChain;
  }

  toSlug(name: string) {
    return name
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 \-]/g, '')
      .replace(/ +/g, '-');
  }
}
