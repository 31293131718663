import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MainSettings } from '../vendors/directus/interfaces/main-settings.interface';
import { filter, take } from 'rxjs/operators';

export interface MetaData {
  title: string;
  description: string;
  keywords: string[];
}

enum Robots {
  ALLOW,
  DENY,
}

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  defaultMeta: MetaData;
  private renderer: Renderer2;

  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  constructor(
    private meta: Meta,
    private title: Title,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(ms => {
      this.defaultMeta = {
        title: ms.seo_title,
        description: ms.seo_description,
        keywords: ms.seo_keywords ? ms.seo_keywords : [],
      };
      this.resetToDefault();
      if (ms.apple_app_id) {
        this.addAppStoreMeta(ms.apple_app_id);
      }
    });
  }

  /**
   * @description Reset to default CMS metadata (sets crawling to be allowed, call blockCrawling()
   * immediately after if crawling needs to be blocked)
   */
  resetToDefault() {
    this.mainSettings$
      .pipe(
        filter(ms => ms !== null),
        take(1)
      )
      .toPromise()
      .then(() => {
        this.updateTitle(null, false);
        this.updateDescription(this.defaultMeta.description);
        this.updateKeywords(this.defaultMeta.keywords);
        this.updateMisc();
        this.setCanonical();
        this.allowCrawling();
      });
  }

  /**
   * @description Set Page Metadata (sets crawling to be allowed, call blockCrawling() immediately after if crawling needs to be blocked)
   * @param metadata - set property to null to use default SEO data from CMS
   * @param titleOverride - set to true if you want to remove the brands name from the title
   */
  manualUpdate(metadata: MetaData, titleOverride = false) {
    this.mainSettings$
      .pipe(
        filter(ms => ms !== null),
        take(1)
      )
      .toPromise()
      .then(() => {
        this.updateTitle(metadata.title, titleOverride);
        this.updateDescription(metadata.description);
        this.updateKeywords(metadata.keywords);
        this.updateMisc();
        this.setCanonical();
        this.allowCrawling();
      });
  }

  /**
   * @description Sets robots metadata to allow crawling on page
   */
  allowCrawling() {
    this.updateRobots(Robots.ALLOW);
  }

  /**
   * @description Sets robots metadata to block crawling on page
   */
  blockCrawling() {
    this.updateRobots(Robots.DENY);
  }

  private updateTitle(title: string, titleOverride: boolean) {
    let formattedTitle;
    if (title) {
      formattedTitle = titleOverride ? title : `${title} | ${this.defaultMeta.title}`;
    } else {
      formattedTitle = this.defaultMeta.title;
    }
    this.title.setTitle(formattedTitle);
    this.checkAndUpdate({ name: 'title', content: formattedTitle });
    this.checkAndUpdate({ property: 'og:title', content: formattedTitle });
    this.checkAndUpdate({ name: 'twitter:title', content: formattedTitle });
  }

  private updateDescription(description: string): void {
    const content = description ? description : this.defaultMeta.description;
    this.checkAndUpdate({ name: 'description', content });
    this.checkAndUpdate({ property: 'og:description', content });
    this.checkAndUpdate({ name: 'twitter:description', content });
  }

  private updateKeywords(keywords: string[]): void {
    if (keywords && keywords.length > 0) {
      this.checkAndUpdate({ name: 'keywords', content: keywords.join(', ') });
    } else {
      this.checkAndUpdate({ name: 'keywords', content: this.defaultMeta.keywords.join(', ') });
    }
  }

  private updateRobots(mode: Robots) {
    switch (mode) {
      case Robots.ALLOW:
        this.checkAndUpdate({ name: 'robots', content: 'index, follow' });
        this.checkAndUpdate({ name: 'googlebot', content: 'all' });
        break;
      case Robots.DENY:
        this.checkAndUpdate({ name: 'robots', content: 'noindex, nofollow' });
        this.checkAndUpdate({ name: 'googlebot', content: 'none' });
        break;
    }
  }

  private updateMisc() {
    this.checkAndUpdate({ property: 'og:type', content: 'website' });
    this.checkAndUpdate({ property: 'og:url', content: `${window.location.origin}${window.location.pathname}` });
  }

  private setCanonical() {
    const canonical: HTMLLinkElement = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.href = `${window.location.origin}${window.location.pathname}`;
    } else {
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'canonical');
      this.renderer.setAttribute(link, 'href', `${window.location.origin}${window.location.pathname}`);
      this.renderer.appendChild(document.querySelector('head'), link);
    }
  }

  private checkAndUpdate(tag: MetaDefinition) {
    if (this.meta.getTag(tag.name ? `name='${tag.name}'` : `property='${tag.property}'`)) {
      this.meta.updateTag(tag, tag.name ? `name='${tag.name}'` : `property='${tag.property}'`);
    } else {
      this.meta.addTag(tag);
    }
  }

  private addAppStoreMeta(appID: string) {
    this.meta.addTag({ name: 'apple-itunes-app', content: `app-id=${appID}` });
  }
}
