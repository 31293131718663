import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {Order} from 'src/interfaces/order.interface';
import {User} from 'src/interfaces/user.interface';
import {VendorSetup} from 'src/vendors/directus/interfaces/vendor.interface';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CancelEditOrderModalComponent} from '@modules/profile/components/cancel-edit-order-modal/cancel-edit-order-modal.component';
import {ToastService} from '../../../../../services/toast.service';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import {Select, Store} from '@ngxs/store';
import {SetRouteBack, UpdateTitle} from '../../../../../store/actions/app.actions';
import {SetPastOrders} from '../../../../../store/actions/user.actions';
import {CancelOrder, EditOrder, ReorderPastOrder} from '../../../../../store/actions/order.actions';
import {MetaService} from '../../../../../services/meta.service';
import {MobileService} from '../../../../../services/mobile.service';
import {NavigationService} from '@modules/navigation/services';
import {SentryService} from '@common/services';

@Component({
  selector: 'app-orders',
  template: '',
})
export class OrdersComponent implements OnDestroy, OnInit {

  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.order.order) order$: Observable<Order>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.user.pastOrders) pastOrders$: Observable<Order[]>;
  @Select(state => state.order.currencyCode) currencyCode$: Observable<string>;
  // Page details for SEO
  title = 'past orders';
  // Display variables for view
  displayVendorSetup: VendorSetup;
  displayOrder: Order;
  displayUser: User;
  displayPreviousOrders: Order[] = [];
  isLoading;
  error;
  successMessage;
  newOrder = false;
  reordered: boolean;
  navToCheckout = true;
  modalRef: NgbModalRef;
  locationsRoute = this.navigation.getLocationsPageSlug();
  previousOrdersRoute = this.navigation.getPreviousOrdersPageSlug();
  private subs: Subscription[] = [];

  constructor(
      private router: Router,
      private navigation: NavigationService,
      // private pageService: OrdersPageService,
      // private navbarService: NavBarService,
      private modalService: NgbModal,
      private toast: ToastService,
      private store: Store,
      private meta: MetaService,
      public mobile: MobileService,
      private sentry: SentryService
  ) {}

  ngOnInit() {
    this.store.dispatch(new UpdateTitle(this.title));
    this.store.dispatch(new SetRouteBack(''));
    this.store.dispatch(new SetPastOrders());
    this.sentry.setTransactionName('Past Orders');
    this.meta.manualUpdate({
      title: 'Past Orders',
      description: null,
      keywords: null
    });
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    // Navbar service data
    this.subs.push(this.vendorSetup$.subscribe(vendorSetup => {
      if (vendorSetup) {
        this.displayVendorSetup = vendorSetup;
      }
    }));
    this.subs.push(this.order$.subscribe(order => {
      if (order) {
        this.displayOrder = order;
      }
      this.error = null;
      this.successMessage = null;
      this.isLoading = false;
    }));
    this.subs.push(this.user$.subscribe(user => {
      this.displayUser = user ? user : null;
    }));
    // Page service data
    this.subs.push(this.pastOrders$.pipe(filter(o => o !== null),
      distinctUntilChanged(((prev, next) => prev && prev.length === next.length && prev[0]?.orderStatus === next[0]?.orderStatus))).
      subscribe(orders => {
        this.displayPreviousOrders = orders;
        console.log(this.displayPreviousOrders);
    }));
    // this.subs.push(this.pageService.loading$.subscribe(loading => {
    //   this.isLoading = loading;
    // }));
  }

  ngOnDestroy() {
    if (this.subs.length > 0) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  reorder(order: Order) {
    this.error = null;
    this.successMessage = null;
    this.isLoading = true;
    this.modalService.dismissAll();
    this.store.dispatch(new ReorderPastOrder(order)).toPromise().then(() => {
      this.navigation.navigateToCheckoutPage().then(() => {
        this.isLoading = false;
      });
    }).catch((error) => {
      this.isLoading = false;
      if (error && error.error && error.error.message) {
        this.error = error.error.message;
        this.toast.danger(error.error.message);
      } else {
        this.error = 'There was an error starting your reorder';
        this.toast.danger('There was an error starting your reorder');
      }
    });
  }

  viewConfirmation(order: Order) {
    this.navigation.navigateToConfirmationPage(order.orderID).then(() => this.modalService.dismissAll());
  }

  editOrderClicked(order: Order) {
    this.error = null;
    this.successMessage = null;
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(CancelEditOrderModalComponent, { centered: true, modalDialogClass: 'condensed-modal' });
    this.modalRef.componentInstance.isCancelOrder = false;
    this.modalRef.componentInstance.cancelOrEditClicked.subscribe(() => {
      this.isLoading = true;
      this.modalRef.dismiss();
      this.store.dispatch(new EditOrder(order)).toPromise().then((newState) => {
        this.isLoading = false;
        // tslint:disable-next-line:max-line-length
        const menuID = newState.order.order.location.slugURL ? newState.order.order.location.slugURL : newState.order.order.location.locationID;
        this.navigation.navigateToMenuPage(menuID);
      }).catch(error => {
        if (error && error.error && error.error.message) {
          this.error = error.error.message;
          this.toast.danger(error.error.message);
        } else {
          this.error = 'There was an error editing this order';
          this.toast.danger('There was an error editing this order');
        }
        this.isLoading = false;
        this.store.dispatch(new SetPastOrders());
      });
    });
  }

  routeToProfile() {
    this.navigation.navigateToProfilePage();
  }

  cancelOrderClicked(order: Order) {
    this.error = null;
    this.successMessage = null;
    this.modalService.dismissAll();
    this.modalRef = this.modalService.open(CancelEditOrderModalComponent, {centered: true, modalDialogClass: 'condensed-modal',});
    this.modalRef.componentInstance.isCancelOrder = true;
    this.modalRef.componentInstance.cancelOrEditClicked.subscribe(() => {
      this.isLoading = true;
      this.modalRef.dismiss();
      this.store.dispatch(new CancelOrder(order)).toPromise().then(() => {
        this.isLoading = false;
        this.toast.success('Order Canceled');
        this.successMessage = 'Order Canceled';
        this.store.dispatch(new SetPastOrders());
      }).catch(error => {
        if (error && error.error && error.error.message) {
          this.error = error.error.message;
          this.toast.danger(error.error.message);
        } else {
          this.error = 'There was an error canceling this order';
          this.toast.danger('There was an error canceling this order');
        }
        this.isLoading = false;
        this.store.dispatch(new SetPastOrders());
      });
    });
  }

}
