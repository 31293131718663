import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import {
  AbstractControl,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CardDetails, MultiPayDetails } from 'src/interfaces/card-details.interface';
import { CheckoutPageService } from './checkout-page.service';
import { Order } from 'src/interfaces/order.interface';
import { User } from 'src/interfaces/user.interface';
import { OrderItem } from 'src/interfaces/product.interface';
import { Menu } from 'src/interfaces/menu.interface';
import { combineLatest, forkJoin, merge, Observable, Subject, Subscription } from 'rxjs';
import { formatCurrency } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../../../../../services/error.service';
import { Location, Location as DineEngineLocation } from '../../../../../interfaces/location.interface';
import { DateTimeService } from 'src/services/date-time.service';

import { Reward } from 'src/interfaces/reward.interface';
import {
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  first,
  map,
  skip,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import moment, { Moment } from 'moment-timezone';
import { DirectusService } from '../../../../../vendors/directus/directus.service';
import { VendorSetup } from 'src/interfaces/vendor.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { AddressService } from '@modules/utility/services/address.service';
import { DineEngineError } from 'src/interfaces/dineengine-error.interface';
import { UserLocationService } from '@modules/locations/services/user-locations.service';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { Address } from '../../../../../interfaces/address.interface';
import { ToastService } from '../../../../../services/toast.service';
import { GiftCardItem } from 'src/interfaces/gift-card-item.interface';
import { PaymentTypes } from '../../../../../interfaces/payment-types.enum';
import { NgbModal, NgbModalOptions, NgbModalRef, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { AgeCheckComponent } from '@modules/cart/components/age-check/age-check.component';
import { ModeService } from '../../../../../services/mode.service';
import { Select, Store } from '@ngxs/store';
import { SetRouteBack, UpdateTitle } from '../../../../../store/actions/app.actions';
import {
  AddPromoCode,
  AddTip,
  ClearOrder,
  RedeemReward,
  RemoveFromOrder,
  RemovePromoCode,
  RemoveReward,
  SetAvailableOrderDays,
  SetAvailableOrderTimes,
  SetCustomField,
  SetHandoffType,
  SetOrderReadyTime,
  StageReward,
  SubmitPayment,
  UpdateBasketItem,
  UpdateDelivery,
  ValidateOrder,
} from '../../../../../store/actions/order.actions';
import { SetProduct } from '../../../../../store/actions/menu.actions';
import { produce } from 'immer';
import { SetUserSavedAddress } from 'src/store/actions/location.actions';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import {
  CreateAccount,
  SetLoyaltyLocations,
  SetRewards,
  SetSavedCards,
  SignIn,
  SignInWithApple as SignInWithAppleStateAction,
  SignInWithFacebook,
  UpdateAppliedRewards,
} from '../../../../../store/actions/user.actions';
import { MetaService } from '../../../../../services/meta.service';
import { MainSettings } from '../../../../../vendors/directus/interfaces/main-settings.interface';
import { LoyaltyLoginModalComponent } from '@modules/checkout/components/loyalty-login-modal/loyalty-login-modal.component';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { CreateAccount as NewAccount } from '../../../../../interfaces/create-account.interface';
import { NavigationService } from '@modules/navigation/services';
import { Capacitor } from '@capacitor/core';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { Branding } from '../../../../../vendors/directus/interfaces/branding.interface';
import { TrancloudProviderService } from '../../../../../vendors/datacap-trancloud/services/trancloud-provider.service';
import { OrderSubmissionModalComponent, RemoveInvalidProductsComponent } from '@modules/checkout/components';
import { OloPayService } from '@modules/checkout/services';
import { RewardsBalances } from '../../../../../interfaces/rewards-balances.interface';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { ThemeColor } from '../../../../../vendors/directus/interfaces/theme-color.interface';
import {
  collapseAnimation,
  collapseHorizontallyAnimation,
  collapseLeftOnLeaveAnimation,
  expandRightOnEnterAnimation,
} from 'angular-animations';
import { UtilityService } from '@modules/utility/services';
import { CustomField } from '../../../../../interfaces/custom-fields.interface';
import { CartService } from '@modules/cart/services';
import { MenuService } from '@modules/menu/services';
import { OloPay } from '@dineengine/capacitor-olo-pay';
import config from 'capacitor.config';
import { TextField } from '../../../../../vendors/directus/interfaces/text-field.interface';
import * as validator from 'card-validator';
import { environment } from '../../../../../environments/environment';
import { GlobalStateModel } from '../../../../../store/state.model';
import { IonDatetimeCustomEvent } from '@ionic/core';
import { RateApp } from 'capacitor-rate-app';
import { UserField } from '../../../../../interfaces/user-field';
import { CardTerminalService } from '../../../../../services/vendor-config-service/card-terminal.service';
import { Card } from '../../../../../vendors/paytronix/interfaces/account-info-response.model';
import {
  CardTerminalCartDisplayInfo,
  CardTerminalCartDisplayInfoItem,
} from '../../../../../interfaces/card-terminal-cart-display-info.interface';
import { AnimationOptions } from 'ngx-lottie';
import { KioskStartOverModalComponent } from '@modules/kiosk/components/kiosk-start-over-modal/kiosk-start-over-modal.component';

declare let DatacapWebToken: {
  requestToken(token: string, id: string, callback: (response: any) => void);
};

@Component({
  selector: 'app-checkout-component',
  template: '',
  providers: [CheckoutPageService, OloPayService],
  animations: [collapseAnimation(), expandRightOnEnterAnimation(), collapseLeftOnLeaveAnimation(), collapseHorizontallyAnimation()],
})
export class CheckoutComponent implements OnDestroy, AfterViewInit, OnInit {
  @Select((state: GlobalStateModel) => state.order.order)
  order$: Observable<Order>;
  @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
  @Select((state: GlobalStateModel) => state.menu.menu) menu$: Observable<Menu>;
  @Select((state: GlobalStateModel) => state.user.rewards) rewards$: Observable<Reward[]>;
  @Select((state: GlobalStateModel) => state.app.vendorSetup)
  vendorSetup$: Observable<VendorSetup>;
  @Select((state: GlobalStateModel) => state.user.savedCards)
  savedCards$: Observable<SavedCard[]>;
  @Select((state: GlobalStateModel) => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select((state: GlobalStateModel) => state.order.currencyCode)
  currencyCode$: Observable<string>;
  @Select((state: GlobalStateModel) => state.app.branding)
  branding$: Observable<Branding>;
  @Select((state: GlobalStateModel) => state.user.rewardPoints)
  rewardPoints$: Observable<RewardsBalances>;
  @Select((state: GlobalStateModel) => state.user.loyaltyLocations)
  loyaltyLocations$: Observable<any>;
  @Select((state: GlobalStateModel) => state.app.theme) theme$: Observable<ThemeColor[]>;
  @Select((state: GlobalStateModel) => state.order.stagedReward)
  stagedReward$: Observable<Reward>;
  @Select((state: GlobalStateModel) => state.app.textField)
  textFields$: Observable<TextField>;
  @Select((state: GlobalStateModel) => state.order.availableOrderDays)
  availableOrderDays$: Observable<Date[]>;
  @Select((state: GlobalStateModel) => state.order.availableOrderTimes)
  availableOrderTimes$: Observable<Moment[]>;
  @Select((state: GlobalStateModel) => state.user.additionalUserFields)
  additionalUserFields$: Observable<UserField[]>;

  @ViewChild('modifyModal') modifyModalRef: TemplateRef<any>;
  @ViewChild('birthdayPick', /* TODO: add static flag */ {}) birthdayPick;

  signInWithAppleOptions: SignInWithAppleOptions = {
    clientId: config.appId,
    redirectURI: config.server.hostname + '/login',
    scopes: 'name email',
    state: '12345',
  };
  // Page details for SEO
  title = 'checkout';
  // Display variables for view
  displayOrder: Order;
  displayUser: User;
  displayGuestUser: User;
  displayMenu: Menu;
  displayRewards: Reward[];
  displayVendorSetup: VendorSetup;
  displaySavedCards: SavedCard[];
  displaySavedGiftCards: SavedCard[];
  displayBranding: Branding;
  areRewardsLoading = true;
  disableCoupon = false;
  giftCards: GiftCardItem[] = [];
  paymentProcessor: string;
  datacapWebToken: string;
  isPickup = true;
  cartProductLoading = [];
  selectedDate = [];
  selectedTime = [];
  dateIndex;
  timeIndex;
  isAsap = true;
  timeSelection: Date | 'asap';
  dateSelection; // : Date;
  tipLoading = false;
  promoLoading = false;
  giftLoading = false;
  savedCard = true;
  confirmationGuid: string;
  supportsSpecialInstructions = false;
  supportsDeliveryInstructions = false;
  phoneNumberRequired = true;
  instructionsMaxLength = 50;
  selectedTip = 20;
  selectedSavedCard: SavedCard;
  selectedCardID: number;
  customFields = [];
  giftCard1Loading = false;
  giftCard2Loading = false;
  loadingHandoff: HandoffType;
  loadingTimeMode: 'advance' | 'asap' = null;
  tokenCallback: (response: any) => void;
  animationsDisabled = false;
  paymentTouched = false;
  basketProdID = '';
  showPassword = false;
  showPassword2 = false;
  @Output() deliveryButtonClick = new EventEmitter<DineEngineLocation>();
  @Output() pickupButtonClick = new EventEmitter<DineEngineLocation>();
  @Output() deliveryClick = new EventEmitter<any>();
  @Output() pickupClick = new EventEmitter<any>();
  submitting = false;
  errorMessage = '';
  addCardClicked = false;
  // Rewards
  applyingReward = false;
  loadingReward = null;
  areRewardsSelecting = false;
  form = new UntypedFormGroup({
    phone: new UntypedFormControl(),
  });

  userForm = new UntypedFormGroup(
    {
      firstName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
      emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      guestOptIn: new UntypedFormControl(false),
      signUpOptIn: new UntypedFormControl(false),
      password: new UntypedFormControl('', [Validators.required, this.passwordValidator()]),
      password2: new UntypedFormControl('', [Validators.required, this.passwordValidator()]),
      birthday: new UntypedFormControl(''),
      emailOptIn: new UntypedFormControl(true),
      smsOptIn: new UntypedFormControl(true),
    },
    this.passwordMatchValidator
  );
  deliveryForm = new UntypedFormGroup({
    streetAddress: new UntypedFormControl('', Validators.required),
    suite: new UntypedFormControl(),
    city: new UntypedFormControl('', Validators.required),
    state: new UntypedFormControl('', Validators.required),
    zip: new UntypedFormControl('', Validators.required),
    specialInstruction: new UntypedFormControl(),
  });
  creditCardForm = new UntypedFormGroup({
    ccName: new UntypedFormControl('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
    ccNumber: new UntypedFormControl('', [Validators.required, Validators.minLength(15)]),
    ccCVV: new UntypedFormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]),
    ccExpiration: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
    ccZip: new UntypedFormControl('', Validators.required),
    ccSave: new UntypedFormControl(true, []),
    ccBillingCountry: new UntypedFormControl('US'),
    ccStreetAddress: new UntypedFormControl(''),
    ccStreetAddress2: new UntypedFormControl(''),
    ccCity: new UntypedFormControl(''),
  });
  savedCreditCardForm = new UntypedFormGroup({
    savedCards: new UntypedFormControl('', [Validators.required]),
    scCVV: new UntypedFormControl('', [Validators.minLength(3), Validators.maxLength(4)]),
  });
  giftCardForm = new UntypedFormGroup({
    gcNumber1NoPin: new UntypedFormControl(),
    gcNumber1Pin: new UntypedFormControl(),
    gcPin1: new UntypedFormControl(),
    saveGC1: new UntypedFormControl(false),
    savedGiftCard1ID: new UntypedFormControl(),
    gcNumber2NoPin: new UntypedFormControl(),
    gcNumber2Pin: new UntypedFormControl(),
    gcPin2: new UntypedFormControl(),
    saveGC2: new UntypedFormControl(false),
    savedGiftCard2ID: new UntypedFormControl(),
  });
  singleGiftCardForm = new UntypedFormGroup({
    gcNumber: new UntypedFormControl(),
    gcPin: new UntypedFormControl(),
    saveGC: new UntypedFormControl(true),
    savedGiftCardID: new UntypedFormControl(null),
  });
  promoCodeForm = new UntypedFormGroup({
    promoCode: new UntypedFormControl(),
  });
  tipForm = new UntypedFormGroup({
    tipAmount: new UntypedFormControl(),
  });
  customFieldsForm = new UntypedFormGroup({});
  dineInForm = new UntypedFormGroup({});
  curbsideForm = new UntypedFormGroup({});
  paymentInfo = {};
  canScan = false;
  selectedBilling = 'creditcard';
  handoffs = HandoffType;
  paymentTypes = PaymentTypes;
  amExMask = '0000-000000-000009';
  regularMask = '0000-0000-0000-0000';
  currentCardMask = this.regularMask;
  currentCreditCardType = 'unknown';
  currentCreditCardMask = '0000-0000-0000-0000';
  currentCVVMask = '0000';
  currentCVVLabel = 'CVV';
  usaZipMask = '00000';
  canadaZipMask = 'AAA AAA';
  currentZipMask = this.usaZipMask;
  usaZipPlaceholder = '_ _ _ _ _';
  canadaZipPlaceholder = '_ _ _   _ _ _';
  currentZipPlaceholder = this.usaZipPlaceholder;
  usaZipInputMode = 'numeric';
  canadaZipInputMode = 'text';
  currentZipInputMode = this.usaZipInputMode;
  usaZipLabel = 'Zip Code';
  canadaZipLabel = 'Postal Code';
  currentZipLabel = this.usaZipLabel;
  usaCurrencyCode = 'USD';
  canadaCurrencyCode = 'CAD';
  currentCurrencyCode = this.usaCurrencyCode;
  orderType;
  tempOrderType;
  paymentType = -1;
  isLoading = false;
  menuHref;
  cartHref;
  locationID;
  orderProvider;
  providerName;
  hideGiftCardPin = false;
  showSavedCardCVV = false;
  giftCard = 0;
  giftCardMask = '0000-0000-0000-0000';
  paymentMethod: any;
  loyaltyLocations = null;
  emailOptInCopy = 'Send me emails about future deals and features.';
  signUpOptInCopy = 'Join Now to earn rewards when you order.';
  newAccountEmailOptInCopy = 'Send me emails about future deals and features.';
  newAccountSMSOptInCopy = 'Send me text messages about future deals and features. SMS data rates may apply.';

  waitingOnTimeToBeSelected = false;

  loyaltyLoginModalRef: NgbModalRef;

  supportsApplePay = false;
  supportsGooglePay = false;

  appliedLoyaltyBalance = {
    balanceCents: 0,
    cardNumber: null,
    cardPin: null,
  };

  curbsideFields: CustomField[] = [];
  dineInFields: CustomField[] = [];

  earliestBDay = moment().subtract(13, 'years').subtract(1, 'day').toDate();
  earliestBDayISO = this.earliestBDay.toISOString().split('T')[0];

  deliveryInstructionLength = 128;

  capacitor = Capacitor; // for template use

  oloPayComplete = false;
  checkoutMessage = '';

  // Kiosk Variables
  nameForm = new UntypedFormGroup({
    guestName: new FormControl<string>('', [Validators.required, Validators.pattern('^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$')]),
    emailAddress: new FormControl<string>('', [Validators.email]),
    phoneNumber: new FormControl<string>('', [Validators.required]),
  });

  tableNumberForm = new UntypedFormGroup({
    tableNumber: new FormControl<string>(''),
  });

  activeSection = 'selectPaymentType';
  kioskPaymentTypes = {
    prepaid: 'prepaid',
    cash: 'cash',
  };

  filteredPaymentTypes: PaymentTypes[] = [];

  private subs: Subscription[] = [];
  @ViewChild('content', /* TODO: add static flag */ {}) private content: any;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  private oloPayService: OloPayService = new OloPayService();

  formatter = (result: Location) => result.name;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance?.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      withLatestFrom(this.loyaltyLocations$),
      map(([term, loyaltyLocations]) => {
        return !term ? loyaltyLocations : loyaltyLocations.filter(location => location.name.toLowerCase().includes(term.toLowerCase()));
      })
    );
  };

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  additionalFieldsForm = new UntypedFormGroup({});

  constructor(
    private toast: ToastService,
    private router: Router,
    private navigation: NavigationService,
    private pageService: CheckoutPageService,
    private errorService: ErrorService,
    private dateTimeService: DateTimeService,
    private directus: DirectusService,
    private addressService: AddressService,
    private userLocation: UserLocationService,
    private modalService: NgbModal,
    private modeService: ModeService,
    private store: Store,
    private orderTypeService: OrderTypeService,
    private meta: MetaService,
    private analytics: AnalyticsService,
    private utility: UtilityService,
    public menuService: MenuService,
    public cartService: CartService,
    protected cardTerminal: CardTerminalService
  ) {}

  ngOnInit() {
    this.additionalUserFields$.subscribe(fields => {
      if (fields && fields.length > 0) {
        fields.forEach(field => {
          const formControl = new UntypedFormControl('', field.required ? [Validators.required] : []);
          this.additionalFieldsForm.addControl(field.providerFieldName, formControl);
        });
      }
    });
    const interval = setInterval(() => {
      const order = this.store.selectSnapshot((state: GlobalStateModel) => state.order.order);
      if (order && this.instance) {
        (document.getElementById('typeahead-focus') as HTMLInputElement).value = order.location.name;
        clearInterval(interval);
      }
    }, 250);
    this.subs.push(
      this.textFields$.subscribe(fields => {
        if (fields.checkout_account_creation_email_opt_in_copy) {
          this.newAccountEmailOptInCopy = fields.checkout_account_creation_email_opt_in_copy;
        }
        if (fields.checkout_account_creation_sms_opt_in_copy) {
          this.newAccountSMSOptInCopy = fields.checkout_account_creation_sms_opt_in_copy;
        }
      })
    );
    OloPay.supportsApplePay().then(result => (this.supportsApplePay = result.supported));
    OloPay.supportsGooglePay().then(result => (this.supportsGooglePay = result.supported));
    this.store.dispatch(new SetLoyaltyLocations());
    combineLatest([this.mainSettings$, this.user$.pipe(filter(u => u !== null)), this.vendorSetup$])
      .pipe(take(1))
      .subscribe(([ms, user, vs]) => {
        // tslint:disable-next-line:max-line-length
        if (
          ms.prompt_login_at_checkout &&
          user.isGuest &&
          vs.user_provider !== 'none' &&
          this.modeService.mode !== 'tableside' &&
          this.modeService.mode !== 'kiosk'
        ) {
          this.openLoyaltyLogin();
        }
      });
    combineLatest([this.mainSettings$, this.order$.pipe(filter(o => o !== null))])
      .pipe(take(1))
      .subscribe(([ms, order]) => {
        this.displayOrder = order;
        const coupon = sessionStorage.getItem('coupon');
        if (coupon) {
          this.store
            .dispatch(new AddPromoCode(coupon))
            .toPromise()
            .then(() => sessionStorage.removeItem('coupon'))
            .catch(error => this.toast.danger(this.errorService.rewardApplyError(error)));
        }
        if (order.isASAP) {
          this.store.dispatch(new SetAvailableOrderTimes(moment(order.earliestReadyTimestamp).startOf('day').toDate()));
        } else {
          this.store.dispatch(new SetAvailableOrderTimes(moment(order.orderReadyTimestamp).startOf('day').toDate()));
        }
        order?.location?.curbsideCustomFields?.forEach(cCF => {
          this.curbsideForm.addControl(
            this.utility.toSlug(cCF.label),
            new UntypedFormControl(null, cCF.required ? [Validators.required] : [])
          );
        });
        order?.location.dineInCustomFields?.forEach(dCF => {
          this.dineInForm.addControl(
            this.utility.toSlug(dCF.label),
            new UntypedFormControl(
              dCF.label.toLowerCase().includes('table')
                ? this.modeService.mode === 'kiosk'
                  ? sessionStorage.getItem('kioskTable')
                  : this.modeService.mode === 'tableside'
                    ? sessionStorage.getItem('tablenumber')
                    : null
                : null,
              dCF.required ? [Validators.required] : []
            )
          );
        });
        this.curbsideFields = order?.location?.curbsideCustomFields;
        this.dineInFields = order?.location.dineInCustomFields;
        if (order.canTip && ms.default_tip_amount) {
          this.addTip(Number(ms.default_tip_amount));
          if (![0, 15, 20, 25].includes(Number(ms.default_tip_amount))) {
            this.selectedTip = null;
          }
        } else {
          this.selectedTip = 0;
        }
        this.guestOptIn.setValue(ms.auto_select_email_opt_in);
      });
    this.meta.resetToDefault();
    this.meta.blockCrawling();
    setTimeout(() => {
      (window as any).prerenderReady = true;
    }, 1000);
    // combineLatest([
    //   this.order$.pipe(filter(o => o !== null), distinctUntilKeyChanged('items')),
    //   this.user$.pipe(filter(u => u !== null)),
    // ]).subscribe(([order, user]) => {
    //   if (!user.isGuest) {
    //     this.store.dispatch(new SetRewards(user.userID, order?.location?.locationID));
    //   }
    // });
    this.store.dispatch(new UpdateTitle(this.title));
    // Navbar service data
    this.subs.push(
      this.vendorSetup$.pipe(filter(v => v !== null)).subscribe(setup => {
        if (setup) {
          this.displayVendorSetup = setup;
          if (setup.order_provider.includes('olo')) {
            this.directus.getOloSettings().subscribe(res => {
              this.hideGiftCardPin = res.hide_gift_card_pin;
              if (res.gift_card_mask) {
                this.giftCardMask = res.gift_card_mask;
              }
              const gcs = JSON.parse(sessionStorage.getItem('giftCards'));
              if (gcs && gcs.length) {
                this.giftCards = gcs;
                this.setupGiftCards();
              }
            });
          } else if (setup.order_provider.includes('novadine')) {
            this.showSavedCardCVV = true;
          }
          if (setup.payment_processing_provider === 'olo-pay') {
            // this.subs.push(this.oloPayService.cardNumberComplete$.subscribe((complete) => this.oloPayComplete = complete));
            this.oloPayComplete = true;
            if (Capacitor.getPlatform() !== 'web') {
              OloPay.supportsApplePay().then(support => {
                this.supportsApplePay = support.supported;
              });
              OloPay.supportsGooglePay().then(support => {
                this.supportsGooglePay = support.supported;
              });
            }
          } else {
            this.oloPayComplete = true;
          }
        }
      })
    );
    this.subs.push(
      this.order$.pipe(filter(o => o !== null)).subscribe(order => {
        if (order) {
          this.store.dispatch(new SetAvailableOrderDays());
          if (order.items?.length < 1) {
            this.navigation.navigateToMenuPage(order?.location?.locationID);
          }
          this.setOrderValues(order);
          this.locationID = order?.location?.locationID;
          if (this.modeService.mode !== 'tableside') {
            this.store.dispatch(new SetRouteBack(`/menu/${this.locationID}`));
          }
          this.instructionsMaxLength = order?.location?.specialInstructionsMaxLength;
          if (order.location && order?.location?.customFields && order?.location?.customFields.length) {
            order?.location?.customFields
              .filter(cf => cf.applicableHandoffs.includes(order.handoffType))
              .forEach(cf => {
                if (this.customFields.filter(c => c.label === cf.label).length === 0) {
                  // tslint:disable-next-line:max-line-length
                  if (
                    !(
                      cf.applicableHandoffs.every(h => h === HandoffType.dineIn) &&
                      cf.label.toLowerCase().includes('table') &&
                      sessionStorage.getItem('tablenumber')
                    )
                  ) {
                    this.customFieldsForm.addControl(cf.label, new UntypedFormControl('', []));
                    this.customFields.push(cf);
                  }
                }
              });
          }
          if (order.location && !order?.location?.supportsAsapOrders) {
            this.scheduleForLaterClicked();
          }
          this.promoLoading = false;
        }
      })
    );
    this.subs.push(
      this.order$
        .pipe(
          filter(o => o !== null),
          take(1)
        )
        .subscribe(order => {
          if (order) {
            if (order.handoffType === HandoffType.delivery || order.handoffType === HandoffType.dispatch) {
              this.specialInstructions.setValue(order.deliveryAddress.specialInstructions);
              if (!this.displayVendorSetup.order_provider.includes('olo')) {
                this.deliveryInstructionLength = order?.location?.specialInstructionsMaxLength;
              }
            }
            if (order.requiresFullBillingAddress) {
              this.creditCardForm.get('ccStreetAddress').setValidators([Validators.required]);
              this.creditCardForm.get('ccCity').setValidators([Validators.required]);
            }
            if (order?.location?.address.country) {
              this.creditCardForm.patchValue({
                ccBillingCountry: order?.location?.address.country,
              });
              this.setPostalCodeFormatting(order?.location?.address.country);
            }
            this.confirmationGuid = order.orderID;
            let orderContainsAlcohol = false;
            order.items.forEach(orderItem => {
              if (orderItem.isAlcohol) {
                orderContainsAlcohol = true;
              }
            });
            if (orderContainsAlcohol && this.modeService.mode === 'tableside') {
              this.openAgeCheckModal();
            }
          }
        })
    );
    this.subs.push(
      this.user$
        .pipe(
          filter(u => u !== null),
          distinctUntilChanged()
        )
        .subscribe(user => {
          if (this.modeService.mode === 'tableside' || sessionStorage.getItem('mode') === 'tableside') {
            if (sessionStorage.getItem('tablesideGuest')) {
              this.userForm.get('firstName').setValue(sessionStorage.getItem('tablesideGuest'));
              this.userForm.get('firstName').disable();
            } else {
              this.userForm.get('firstName').enable();
            }
          }
          if (user && !user.isGuest) {
            this.displayUser = user;
            this.displayGuestUser = null;
            this.userForm.get('firstName').setValue(user.firstName);
            this.userForm.get('lastName').setValue(user.lastName);
            this.userForm.get('emailAddress').setValue(user.email);
            this.userForm.get('phoneNumber').setValue(user.phoneNumber);
            this.signUpOptIn.setValue(false);
            this.firstName.setValidators([Validators.required]);
            this.lastName.setValidators([Validators.required]);
            if (!this.paymentTouched) {
              // this.paymentType = 2;
            }
          } else if (this.modeService.mode !== 'tableside' && sessionStorage.getItem('mode') !== 'tableside') {
            this.displayUser = null;
            this.displayGuestUser = user;
            this.userForm.get('firstName').setValue('');
            this.userForm.get('lastName').setValue('');
            this.userForm.get('emailAddress').setValue('');
            this.userForm.get('phoneNumber').setValue('');
          }
          this.userForm.updateValueAndValidity();
        })
    );
    this.subs.push(
      this.rewards$.pipe(distinctUntilChanged()).subscribe(rewards => {
        if (rewards) {
          this.displayRewards = rewards.filter(r => r.redeemOnline);
          this.analytics.analyticsOnRewardListView(
            this.displayRewards,
            this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
          );
          // this.displayRewards = rewards;
        }
        this.areRewardsLoading = false;
      })
    );
    this.subs.push(
      this.menu$.subscribe(menu => {
        if (menu) {
          this.displayMenu = menu;
        }
      })
    );
    this.subs.push(
      this.savedCards$.pipe(filter(sc => sc !== null)).subscribe(savedCards => {
        this.displaySavedCards = savedCards.filter(card => !card.isGiftCard);
        this.displaySavedGiftCards = savedCards.filter(card => card.isGiftCard);
        if (savedCards && savedCards.length > 0 && !this.paymentTouched) {
          // this.paymentType = PaymentTypes.savedCard;
          // const defaultCard = this.displaySavedCards.find(
          //   (card) => card.isDefault
          // );
          // if (defaultCard) {
          //   this.selectedCardID = Number(defaultCard.savedCardID);
          // } else {
          //   this.selectedCardID = this.displaySavedCards[0]
          //     ? Number(this.displaySavedCards[0].savedCardID)
          //     : null;
          // }
          this.changePaymentMethod(PaymentTypes.savedCard);
        }
      })
    );
    const mode = sessionStorage.getItem('mode');
    if (mode === 'tableside') {
      this.locationID = sessionStorage.getItem('tablesidelocation');
    }
    this.subs.push(
      this.vendorSetup$.pipe(filter(v => v !== null)).subscribe(setup => {
        this.paymentProcessor = setup.payment_processing_provider;
        this.providerName = setup.order_provider;
        this.phoneNumberRequired = this.requirePhoneNumber(this.providerName);
        this.supportsSpecialInstructions = this.showSpecialInstructions(this.providerName);
        this.supportsDeliveryInstructions = this.showDeliveryInstructions(this.providerName);
        if (this.paymentProcessor === 'datacap') {
          this.subs.push(
            this.directus.getDatacapSettings().subscribe(settings => {
              this.datacapWebToken = settings.web_token;
              this.tokenCallback = response => {
                if (response.Error) {
                  this.errorMessage = 'There was an issue processing your payment. Please check your card information and try again.';
                  this.toast.danger(this.errorMessage);
                } else {
                  (document.getElementById('token') as HTMLInputElement).value = response.Token;
                  this.submit().then();
                }
              };
            })
          );
        }
      })
    );
    this.subs.push(
      this.userForm.valueChanges.subscribe(() => {
        this.errorMessage = '';
      })
    );
    this.subs.push(
      this.savedCreditCardForm.valueChanges.subscribe(() => {
        this.errorMessage = '';
      })
    );
    this.subs.push(
      this.creditCardForm.valueChanges.subscribe(() => {
        this.errorMessage = '';
      })
    );
    this.subs.push(
      this.giftCardForm.valueChanges.subscribe(() => {
        this.errorMessage = '';
      })
    );
    this.subs.push(
      this.singleGiftCardForm.valueChanges.subscribe(() => {
        this.errorMessage = '';
      })
    );
    this.subs.push(
      this.user$.pipe(filter(u => u !== null)).subscribe(user => {
        if (!user.isGuest) {
          this.store.dispatch(new SetSavedCards(user.userID, false));
        }
      })
    );
    this.subs.push(
      this.branding$.subscribe(branding => {
        if (branding) {
          this.displayBranding = branding;
        }
      })
    );
    this.subs.push(
      this.order$
        .pipe(take(1))
        .subscribe(order =>
          this.analytics.analyticsOnCheckoutStart(
            order?.items,
            this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
          )
        )
    );
    this.subs.push(
      this.loyaltyLocations$.pipe(filter(ll => !!ll)).subscribe(locations => {
        this.loyaltyLocations = locations;
        if (this.loyaltyLocations !== null) {
          const loyaltyLocations = new UntypedFormControl(null, []);
          if (this.modeService.mode !== 'kiosk') {
            this.userForm.addControl('favoriteLocation', loyaltyLocations);
          }
        }
      })
    );
    this.subs.push(
      this.signUpOptIn.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
        if (value) {
          this.password.setValidators([Validators.required, this.passwordValidator()]);
          this.password2.setValidators([Validators.required, this.passwordValidator()]);
          this.birthday.setValidators([Validators.required, Validators.minLength(8)]);
        } else {
          this.password.setValidators([]);
          this.password.setValue(null);
          this.password2.setValidators([]);
          this.password2.setValue(null);
          this.favoriteLocation?.setValue(null);
          this.favoriteLocation?.setValidators([]);
          this.birthday.setValidators([]);
          this.birthday.setValue([]);
          this.password.updateValueAndValidity();
          this.password2.updateValueAndValidity();
          this.favoriteLocation?.updateValueAndValidity();
          this.birthday.updateValueAndValidity();
        }
      })
    );
    this.subs.push(
      combineLatest([
        this.order$.pipe(
          filter(o => o !== null),
          distinctUntilChanged()
        ),
        this.user$.pipe(
          filter(u => u !== null),
          filter(u => !u.isGuest)
        ),
      ]).subscribe(([order, user]) => {
        // tslint:disable-next-line:max-line-length
        this.store
          .dispatch(new SetRewards(user.userID, order?.location?.locationID))
          .subscribe(() => this.store.dispatch(new UpdateAppliedRewards(order.appliedRewards.length ? order.appliedRewards[0] : null)));
      })
    );
    this.subs.push(
      this.stagedReward$.pipe(filter(sr => sr !== null)).subscribe(async sReward => {
        await this.applyStagedReward(sReward);
      })
    );
    this.ccNumber.valueChanges.subscribe(value => {
      this.setCardFieldProperties(value);
    });

    if (this.modeService.mode === 'kiosk') {
      const kioskPaymentTypes = [this.paymentTypes.prepaid, this.paymentTypes.cash];
      this.filteredPaymentTypes = this.displayOrder.supportedPaymentTypes.filter(type => kioskPaymentTypes.includes(type));

      if (this.filteredPaymentTypes.length === 1) {
        const singlePaymentType = this.filteredPaymentTypes[0];
        if (singlePaymentType === this.paymentTypes.prepaid) {
          this.setupPrepaidScreen();
        } else if (singlePaymentType === this.paymentTypes.cash) {
          this.openPayAtCounterSection();
        }
      }
    }
  }

  ngOnDestroy() {
    sessionStorage.removeItem('giftCards');
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  ngAfterViewInit() {
    this.shouldDisableCoupon();
    if (Capacitor.getPlatform() !== 'web') {
      OloPay.initialize({
        freshInstall: environment.debug,
      }).then(() => {
        if (Capacitor.getPlatform() === 'android') {
          OloPay.initializeGooglePay({
            countryCode: this.displayOrder.location.address.country,
          });
        }
      });
    } else {
      this.subs.push(
        combineLatest([
          this.vendorSetup$.pipe(
            filter(vs => vs !== null),
            first()
          ),
          this.order$.pipe(
            filter(o => o !== null),
            first()
          ),
        ]).subscribe(([vs, o]) => {
          this.paymentProcessor = vs.payment_processing_provider;
          if (this.paymentProcessor === 'olo-pay') {
            const digitalWalletsOptions = this.oloPayService.generateOloPayReciept(o);
            this.oloPayService.initializeSingleField().subscribe((value: any) => {});
            this.oloPayService.initializeWallet(digitalWalletsOptions, this.oloPaySubissionCallback.bind(this)).subscribe((value: any) => {
              if (
                this.oloPayService.digitalWallets.canMakePayment &&
                (this.oloPayService.digitalWallets.canMakePayment.googlePay || this.oloPayService.digitalWallets.canMakePayment.applePay)
              ) {
                const button = document.getElementById('digital-wallets-button');
                if (button) {
                  button.style.display = 'inline';
                  button.onclick = () => {
                    this.oloPayService.digitalWallets.initiatePayment();
                  };
                }
                this.subs.push(
                  this.order$.subscribe(order => {
                    if (order) {
                      this.oloPayService.digitalWallets.updatePaymentDetails(this.oloPayService.generateUpdateOptions(order));
                    }
                  })
                );
              }
            });
            this.oloPayService?.singleCardElement?.element?.on('change', event => {
              if (event.complete) {
              } else if (event.error) {
                const message = event.error.message;
              }
            });
          }
        })
      );
    }
    forkJoin({
      order: this.order$.pipe(
        filter(o => o !== null),
        take(1)
      ),
      user: this.user$.pipe(
        filter(u => u !== null),
        take(1)
      ),
    })
      .toPromise()
      .then(() => {
        const token = document.getElementById('token') as HTMLInputElement;
        if (token) {
          token.value = '';
        }
      });
    this.subs.push(
      this.mainSettings$.subscribe(ms => {
        if (ms.auto_select_sign_up && ms.checkout_user_sign_up) {
          this.signUpOptIn.setValue(true);
        } else {
          this.password.setValidators([]);
          this.password.setValue(null);
          this.password2.setValidators([]);
          this.password2.setValue(null);
          this.favoriteLocation?.setValue(null);
          this.favoriteLocation?.setValidators([]);
          this.favoriteLocation?.updateValueAndValidity();
          this.birthday.setValue(null);
          this.birthday.setValidators([]);
        }
      })
    );
    this.subs.push(
      this.user$.pipe(skip(1), distinctUntilKeyChanged('isGuest'), withLatestFrom(this.order$)).subscribe(([user, order]) => {
        this.oloPayService.userChanged().subscribe(() => {
          this.oloPayService
            .initializeWallet(this.oloPayService.generateOloPayReciept(order), this.oloPaySubissionCallback.bind(this))
            .subscribe(() => {
              console.log('reinitialized');
            });
        });
      })
    );
  }

  // Kiosk Functions
  openPrepaidSection() {
    this.runCardTerminalTransaction();
  }

  setupPrepaidScreen() {
    this.activeSection = this.kioskPaymentTypes.prepaid;
    this.cardTerminal.getService().subscribe(service => {
      if (service) {
        const feeItems: CardTerminalCartDisplayInfoItem[] = this.displayOrder.fees
          ? this.displayOrder.fees.map(fee => {
              return {
                description: fee.description,
                amount: fee.feeCents,
                quantity: 1,
              };
            })
          : [];
        const displayCart: CardTerminalCartDisplayInfo = {
          tax: this.displayOrder.taxCents,
          total: this.displayOrder.subTotalCents,
          items: this.displayOrder.items
            .map(item => {
              return {
                description: item.name,
                amount: item.totalCents,
                quantity: item.quantity,
              };
            })
            .concat(feeItems),
        };
        service.displayCart(displayCart).subscribe();
      }
    });
  }

  openPayAtCounterSection() {
    this.activeSection = this.kioskPaymentTypes.cash;
  }

  backToPaymentSelection() {
    if (this.activeSection === this.kioskPaymentTypes.prepaid) {
      this.startOverClicked();
    }
    this.activeSection = 'selectPaymentType';
  }

  closeKioskPayment() {
    this.backToPaymentSelection();
  }

  openLoyaltyLogin() {
    if (!this.modalService.hasOpenModals()) {
      this.loyaltyLoginModalRef = this.modalService.open(LoyaltyLoginModalComponent, {
        centered: true,
      });

      this.loyaltyLoginModalRef.componentInstance.signedIn.subscribe((logInInfo: { username: string; password: string }) => {
        this.loyaltyLoginModalRef.componentInstance.loginSignUpLoading = true;
        this.store.dispatch(new SignIn(logInInfo.username, logInInfo.password)).subscribe(
          () => {
            this.loyaltyLoginModalRef.componentInstance.loginSignUpLoading = false;
            this.loyaltyLoginModalRef.dismiss();
          },
          error => {
            // console.debug(error);
            this.toast.danger(this.errorService.loginError(error));
            this.loyaltyLoginModalRef.componentInstance.loginSignUpLoading = false;
            this.loyaltyLoginModalRef.componentInstance.errorMessage = this.errorService.loginError(error);
          }
        );
      });

      this.loyaltyLoginModalRef.componentInstance.signedUp.subscribe((accountInfo: NewAccount & { additionalFields: UserField[] }) => {
        this.loyaltyLoginModalRef.componentInstance.loginSignUpLoading = true;
        this.store.dispatch(new CreateAccount(accountInfo, accountInfo.additionalFields)).subscribe(
          () => {
            this.loyaltyLoginModalRef.componentInstance.loginSignUpLoading = false;
            this.loyaltyLoginModalRef.dismiss();
          },
          error => {
            // console.debug(error);
            this.toast.danger(this.errorService.registerError(error));
            this.loyaltyLoginModalRef.componentInstance.errorMessage = this.errorService.registerError(error);
            this.loyaltyLoginModalRef.componentInstance.loginSignUpLoading = false;
          }
        );
      });

      this.loyaltyLoginModalRef.componentInstance.continuedAsGuest.subscribe(() => {
        this.loyaltyLoginModalRef.close();
      });

      this.loyaltyLoginModalRef.componentInstance.facebookLogin.subscribe(() => {
        this.facebookLogin();
      });

      this.loyaltyLoginModalRef.componentInstance.appleSignIn.subscribe(() => {
        this.signInWithApple();
      });
    }
  }

  shouldDisableCoupon() {
    this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(ms => (this.disableCoupon = ms.disable_coupon));
    // this.directus.getSettings().subscribe(config => {
    //   this.disableCoupon = config.disable_coupon;
    // });
  }

  showSpecialInstructions(provName: string): boolean {
    // Novadine: Returns error with properly formatted request in edit order API call. Can be added after resolution.
    // Olo: Does not support order-level special instructions.
    let retValue = true;
    // tslint:disable-next-line:max-line-length
    if (provName === 'olo' || provName === 'punchh-olo' || provName === 'novadine' || provName === 'itwercs') {
      retValue = false;
    }
    return retValue;
  }

  setupGiftCards() {
    this.changePaymentMethod(PaymentTypes.giftCard);
    this.clearGiftCardFields();
    if (this.giftCards.length === 1) {
      if (this.giftCards[0].isSaved) {
        this.giftCardForm.get('savedGiftCard1ID').setValue(this.giftCards[0].savedCardID);
      }
      if (this.hideGiftCardPin) {
        this.giftCardForm.get('gcNumber1NoPin').setValue(this.giftCards[0].cardNumber);
      } else {
        this.giftCardForm.get('gcNumber1Pin').setValue(this.giftCards[0].cardNumber);
        this.giftCardForm.get('gcPin1').setValue(this.giftCards[0].pinNumber);
      }
    } else if (this.giftCards.length === 2) {
      this.addGiftCard(2);
      if (this.giftCards[0].isSaved) {
        this.giftCardForm.get('savedGiftCard1ID').setValue(this.giftCards[0].savedCardID);
        this.giftCardForm.get('saveGC1').setValue(false);
        this.giftCardForm.get('saveGC1').disable();
      }
      if (this.giftCards[1].isSaved) {
        this.giftCardForm.get('savedGiftCard2ID').setValue(this.giftCards[1].savedCardID);
        this.giftCardForm.get('saveGC2').setValue(false);
        this.giftCardForm.get('saveGC2').disable();
      }
      if (this.hideGiftCardPin) {
        this.giftCardForm.get('gcNumber1NoPin').setValue(this.giftCards[0].cardNumber);
        this.giftCardForm.get('gcNumber2NoPin').setValue(this.giftCards[1].cardNumber);
      } else {
        this.giftCardForm.get('gcNumber1Pin').setValue(this.giftCards[0].cardNumber);
        this.giftCardForm.get('gcPin1').setValue(this.giftCards[0].pinNumber);
        this.giftCardForm.get('gcNumber2Pin').setValue(this.giftCards[1].cardNumber);
        this.giftCardForm.get('gcPin2').setValue(this.giftCards[1].pinNumber);
      }
    }
  }

  clearGiftCardFields() {
    this.addGiftCard(0);
    this.giftCardForm.get('gcNumber1NoPin').setValue('');
    this.giftCardForm.get('gcNumber2NoPin').setValue('');
    this.giftCardForm.get('gcNumber2Pin').setValue('');
    this.giftCardForm.get('gcPin2').setValue('');
    this.giftCardForm.get('gcNumber2Pin').setValue('');
    this.giftCardForm.get('gcPin2').setValue('');
  }

  requirePhoneNumber(provName: string): boolean {
    // novadine does not require a phone number
    let retValue = true;
    if (provName === 'novadine') {
      retValue = false;
    }
    return retValue;
  }

  changeToDelivery() {
    this.orderTypeService.startDeliveryOrder();
  }

  showDeliveryInstructions(provName: string): boolean {
    let retValue = true;
    if (provName === 'itwercs' || provName === 'novadine') {
      retValue = false;
    }
    return retValue;
  }

  ionViewWillEnter() {
    this.submitting = false;
  }

  formatDate(date: string | Date) {
    return moment(date).toDate();
  }

  setPostalCodeFormatting(countryCode?: string) {
    if (!countryCode) {
      countryCode = this.ccBillingCountry.value;
    }
    switch (countryCode) {
      case 'CA':
        this.currentZipMask = this.canadaZipMask;
        this.currentZipPlaceholder = this.canadaZipPlaceholder;
        this.currentZipLabel = this.canadaZipLabel;
        this.currentZipInputMode = this.canadaZipInputMode;
        break;
      default:
        this.currentZipMask = this.usaZipMask;
        this.currentZipPlaceholder = this.usaZipPlaceholder;
        this.currentZipLabel = this.usaZipLabel;
        this.currentZipInputMode = this.usaZipInputMode;
        break;
    }
  }

  setOrderValues(order: Order) {
    this.displayOrder = order;
    this.dateTimeService.order = this.displayOrder;
    // tslint:disable-next-line:max-line-length
    if (
      (this.displayOrder.handoffType === HandoffType.dispatch || this.displayOrder.handoffType === HandoffType.delivery) &&
      !this.displayOrder.deliveryAddress.state
    ) {
      this.displayOrder = produce(this.displayOrder, draft => {
        draft.deliveryAddress.state = this.addressService.getStateCodeFromZip(this.displayOrder.deliveryAddress.zipCode);
      });
      // this.displayOrder.deliveryAddress.state = this.addressService.getStateCodeFromZip(this.displayOrder.deliveryAddress.zipCode);
    }
    // if (this.modeService.mode !== 'kiosk') {
    //   this.availableDates = this.dateTimeService.getDates();
    //   if (this.availableDates) {
    //     this.availableTimes = this.dateTimeService.getTimes(
    //       this.availableDates[1]?.value
    //     );
    //   }
    // }
    if (!order?.location?.isOpen) {
      this.isAsap = false;
    }
    // if (!order.isASAP) {
    //   this.isAsap = false;
    //   const selectedTime = this.formatDate(order.orderReadyTimestamp);
    //   this.availableDates.forEach((date, i) => {
    //     if (date && date.value && date.value.start) {
    //       if (
    //         this.formatDate(date.value.start).getDate() ===
    //         selectedTime.getDate()
    //       ) {
    //         this.dateIndex = i;
    //         this.availableTimes = this.dateTimeService.getTimes(
    //           this.availableDates[this.dateIndex].value
    //         );
    //       }
    //     }
    //   });
    //   if (this.dateIndex) {
    //     this.availableTimes.forEach((time, i) => {
    //       if (time && time.value) {
    //         const date = new Date(time.value);
    //         const dateListTime = date.getHours() + ':' + date.getMinutes();
    //         const savedTime =
    //           selectedTime.getHours() + ':' + selectedTime.getMinutes();
    //         if (dateListTime === savedTime) {
    //           this.timeIndex = i;
    //         }
    //       }
    //     });
    //   }
    // }
    this.orderType = this.displayOrder.handoffType;
    if (this.orderType === this.loadingHandoff) {
      this.loadingHandoff = HandoffType.loading;
    }
    // this.store.dispatch(new SetMenu(order?.location?.locationID, this.orderType));
    this.menuHref = '/menu/' + (order?.location?.slugURL ? order?.location?.slugURL : order?.location?.locationID);
    this.cartHref = '/cart/' + order?.location?.locationID;
    if (order.tipCents) {
      const formattedTip = formatCurrency(order.tipCents / 100, 'en_US', '$');
      this.tipForm.get('tipAmount').setValue(formattedTip);
    } else {
      this.tipForm.get('tipAmount').setValue(null);
    }
    if (order.appliedCouponCode) {
      this.promoCodeForm.get('promoCode').setValue(order.appliedCouponCode);
      this.promoCodeForm.get('promoCode').disable();
    } else {
      this.promoCodeForm.get('promoCode').setValue(null);
      this.promoCodeForm.get('promoCode').enable();
    }
    if (order.deliveryAddress) {
      this.deliveryForm.get('streetAddress').setValue(order.deliveryAddress.address1);
      this.deliveryForm.get('suite').setValue(order.deliveryAddress.address2);
      this.deliveryForm.get('city').setValue(order.deliveryAddress.city);
      // tslint:disable-next-line:max-line-length
      this.deliveryForm
        .get('state')
        .setValue(
          order.deliveryAddress.state ? order.deliveryAddress.state : this.addressService.getStateFromZip(order.deliveryAddress.zipCode)
        );
      this.deliveryForm.get('zip').setValue(order.deliveryAddress.zipCode);
    }
    this.tipLoading = false;
    this.promoLoading = false;
    if (order?.deliveryAddress?.specialInstructions) {
      this.deliveryForm.get('specialInstruction').setValue(order.deliveryAddress.specialInstructions);
    }
  }

  asapClicked() {
    this.loadingTimeMode = 'asap';
    this.isAsap = true;
    this.waitingOnTimeToBeSelected = true;
    this.store
      .dispatch(new SetOrderReadyTime('asap'))
      .toPromise()
      .then(() => {
        this.waitingOnTimeToBeSelected = false;
        this.loadingTimeMode = null;
        this.store.dispatch(new ValidateOrder());
      });
    this.dateIndex = 0;
    this.timeIndex = 0;
  }

  scheduleForLaterClicked() {
    this.isAsap = false;
  }

  inputCCEvent(input: any) {
    setTimeout(() => {
      // this.checkAndSwitchMask(input);
      // const cardType = this.getCreditCardType(input.target.value);
      // const newMask = this.getMaskType(cardType);
      // const newCVV = this.getCVVType(cardType);
      // this.currentCreditCardType = cardType;
      // this.currentCardMask = newMask;
      // this.currentCVVMask = newCVV;
      // console.log(cardType, newMask);
    }, 0);
  }

  checkAndSwitchMask(input: any) {
    if (input.target.value.length > 17 && this.currentCardMask === this.amExMask) {
      this.currentCardMask = this.regularMask;
      this.currentCVVMask = '000';
    } else if (this.currentCardMask === this.regularMask && input.target.value.length < 19) {
      this.currentCardMask = this.amExMask;
      this.currentCVVMask = '0000';
    }
  }

  getCreditCardType(creditCardNumber) {
    // start without knowing the credit card type
    let result = 'unknown';

    // first check for MasterCard
    if (/^5[1-5]/.test(creditCardNumber)) {
      result = 'mastercard';
    }
    // then check for Visa
    else if (/^4/.test(creditCardNumber)) {
      result = 'visa';
    }
    // then check for AmEx
    else if (/^3[47]/.test(creditCardNumber)) {
      result = 'amex';
    }
    // then check for Diners
    else if (/3(?:0[0-5]|[68]\d)\d{11}/.test(creditCardNumber)) {
      result = 'diners';
    }
    // then check for Discover
    else if (/6011/.test(creditCardNumber)) {
      result = 'discover';
    }

    return result;
  }

  getMaskType(cardType) {
    const masks = {
      mastercard: '9999 9999 9999 9999',
      visa: '9999 9999 9999 9999',
      amex: '9999 999999 99999',
      diners: '9999 9999 9999 99',
      discover: '9999 9999 9999 9999',
      unknown: '9999 9999 9999 9999',
    };
    return masks[cardType];
  }

  getCVVType(cardType) {
    const masks = {
      mastercard: '999',
      visa: '999',
      amex: '9999',
      diners: '99999',
      discover: '999',
      unknown: '999',
    };
    return masks[cardType];
  }

  deliveryButtonClicked(loc: DineEngineLocation) {
    this.deliveryButtonClick.emit(loc);
  }

  pickupButtonClicked(loc: DineEngineLocation) {
    this.pickupButtonClick.emit(loc);
  }

  clickDeliveryButton() {
    this.isPickup = false;
  }

  clickPickupButton() {
    this.isPickup = true;
  }

  onDateChange(event: Date) {
    this.store.dispatch(new SetAvailableOrderTimes(moment(event).startOf('day').toDate()));
    this.waitingOnTimeToBeSelected = true;
  }

  onTimeChange(event: Date) {
    this.loadingTimeMode = 'advance';
    // this.timeSelection =
    //   event.target.value === 'asap'
    //     ? 'asap'
    //     : this.parseISOString(event.target.value);
    // this.waitingOnTimeToBeSelected = true;
    this.store
      .dispatch(new SetOrderReadyTime(moment(event)))
      .toPromise()
      .then(() => {
        this.waitingOnTimeToBeSelected = false;
        this.loadingTimeMode = null;
      })
      .catch(() => {
        this.waitingOnTimeToBeSelected = true;
        this.loadingTimeMode = null;
        this.timeIndex = 0;
        this.toast.danger('Your order cannot be made at the time you selected. Please select another time');
        this.errorMessage = 'Your order cannot be made at the time you selected. Please select another time';
      });
  }

  onASAPSelected() {
    this.store.dispatch(new SetOrderReadyTime('asap'));
  }

  parseISOString(s) {
    const b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  }

  async submit() {
    const mode = sessionStorage.getItem('mode');
    if (mode === 'tableside') {
      // this.paymentType = PaymentTypes.creditCard;
      this.paymentTouched = true;
    }
    if ((this.paymentType < 0 || !this.paymentTouched) && this.displayOrder.totalCents > 0) {
      this.errorMessage = 'Please select a payment type';
      this.toast.danger('Please select a payment type');
      return;
    }
    this.isLoading = true;
    try {
      this.getCustomFieldsAndSubmit();
    } catch (e) {
      console.error(e);
      this.isLoading = false;
    }
  }

  getCustomFieldsAndSubmit() {
    if (this.customFields && this.customFields.length) {
      this.getAdditionalCustomFields();
    }
    if (this.displayOrder.handoffType === HandoffType.curbside) {
      this.getCurbsideInfo();
    }
    if (this.displayOrder.handoffType === HandoffType.dineIn) {
      this.getDineInInfo();
    }
    this.runSubmitActions();
  }

  getAdditionalCustomFields() {
    this.customFields.forEach(async cf => {
      if (this.modeService.mode === 'tableside' && cf.label === 'Table Number') {
        await this.store
          .dispatch(new SetCustomField(cf.id, sessionStorage.getItem('tablenumber')))
          .toPromise()
          .then(() => {});
      } else if (this.customFieldsForm.get(cf.label)) {
        await this.store
          .dispatch(new SetCustomField(cf.id, this.customFieldsForm.get(cf.label).value))
          .toPromise()
          .then(() => {});
      }
    });
  }

  getCurbsideInfo() {
    Object.keys(this.curbsideForm.controls).forEach(key => this.curbsideForm.get(key).updateValueAndValidity());
    this.curbsideForm.markAllAsTouched();
    if (this.curbsideForm.valid) {
      if (this.displayOrder && this.displayOrder.location && this.displayOrder.location.customFields) {
        this.displayOrder.location.curbsideCustomFields.forEach(async field => {
          if (this.curbsideForm.get(this.utility.toSlug(field.label))) {
            await this.store.dispatch(
              new SetCustomField(
                String(field.id),
                this.parseCustomFieldValue(this.curbsideForm.get(this.utility.toSlug(field.label)).value, field.type)
              )
            );
          }
        });
      }
    } else {
      this.errorMessage = 'Please fill out your car information';
      this.toast.danger('Please fill out your car information');
      this.submitting = false;
      this.isLoading = false;
      throw new Error('Please fill out your car information');
    }
  }

  getDineInInfo() {
    Object.keys(this.dineInForm.controls).forEach(key => {
      if (key === 'table-number') {
        this.dineInForm
          .get(key)
          .setValue(
            this.modeService.mode === 'kiosk'
              ? sessionStorage.getItem('kioskTable')
              : this.modeService.mode === 'tableside'
                ? sessionStorage.getItem('tablenumber')
                : null
          );
      }
      this.dineInForm.get(key).updateValueAndValidity();
    });
    this.dineInForm.markAllAsTouched();
    if (this.dineInForm.valid) {
      if (this.displayOrder && this.displayOrder.location && this.displayOrder.location.dineInCustomFields) {
        this.displayOrder.location.dineInCustomFields.forEach(async field => {
          if (this.dineInForm.get(this.utility.toSlug(field.label))) {
            await this.store.dispatch(
              new SetCustomField(
                String(field.id),
                this.parseCustomFieldValue(this.dineInForm.get(this.utility.toSlug(field.label)).value, field.type)
              )
            );
          }
        });
      }
    } else {
      this.submitting = false;
      this.isLoading = false;
      throw new Error('Please fill out your table information');
    }
  }

  private parseCustomFieldValue(value: string | boolean, type: string): string {
    if (type === 'checkbox') {
      return value ? 'Yes' : 'No';
    }
    return value as string;
  }

  runSubmitActions() {
    let details: CardDetails;
    try {
      details = this.collectCardDetails();
    } catch (e) {
      this.submitting = false;
      this.isLoading = false;
      console.error(e);
      if (this.errorMessage) {
        this.toast.danger(this.errorMessage);
      }
      return;
    }

    if (this.displayOrder.handoffType === HandoffType.curbside) {
      details.curbsideInfo = {};
      Object.keys(this.curbsideForm.controls).forEach(key => {
        details.curbsideInfo[key] = this.curbsideForm.get(key).value;
      });
    }
    const shouldSave = !!(
      (this.paymentType === PaymentTypes.creditCard && this.creditCardForm.get('ccSave').value) ||
      this.singleGiftCardForm.get('saveGC').value ||
      this.giftCardForm.get('saveGC1').value
    );
    const specialInst: string = this.deliveryForm.get('specialInstruction').value;
    let token = null;
    const mode = sessionStorage.getItem('mode');
    if (mode !== 'kiosk') {
      token = (document.getElementById('token') as HTMLInputElement).value;
    }

    this.modalService.open(OrderSubmissionModalComponent, {
      animation: true,
      keyboard: false,
      centered: true,
      backdrop: 'static',
      windowClass: 'order-submission-modal',
    });
    this.store.dispatch(new ValidateOrder()).subscribe(
      () => {
        if (specialInst) {
          this.submitWithInstructions(details, specialInst, shouldSave, token);
        } else {
          this.submitOrder(details, specialInst, shouldSave, token);
        }
      },
      er => {
        this.isLoading = false;
        this.submitting = false;
        this.validateError(er);
      }
    );
  }

  validateError(er: any) {
    this.modalService.dismissAll();
    this.errorMessage = er instanceof DineEngineError ? er.message : this.errorService.checkoutError(er as HttpErrorResponse);
    this.toast.danger(this.errorMessage);
  }

  submitWithInstructions(details, specialInst, shouldSave, token) {
    const newAddress: Address = {
      address1: this.streetAddress.value,
      address2: this.suite.value,
      city: this.city.value,
      state: this.state.value,
      zipCode: this.zip.value,
      latitude: this.displayOrder.deliveryAddress.latitude,
      longitude: this.displayOrder.deliveryAddress.latitude,
      specialInstructions: specialInst,
    };
    this.store
      .dispatch(new UpdateDelivery(newAddress))
      .toPromise()
      .then(() => {
        this.submitOrder(details, specialInst, shouldSave, token);
      });
  }

  submitOrder(details, specialInst, shouldSave, token) {
    this.store
      .dispatch(new SubmitPayment(details, specialInst, this.displayOrder.balanceCents, shouldSave, token))
      .toPromise()
      .then(newState => {
        this.submitSuccess(newState);
      })
      .catch(er => {
        this.submitError(er);
      });
  }

  submitSuccess(state: any) {
    this.storeAddresses();
    this.modalService.dismissAll();
    if (Capacitor.getPlatform() !== 'web') {
      Haptics.notification({
        type: NotificationType.Success,
      })
        .then()
        .catch(console.log);
      RateApp.requestReview();
    }
    this.navigateToConfirmation(state.order.previousOrder.orderID);
  }

  submitError(er: any) {
    this.modalService.dismissAll();
    if (Capacitor.getPlatform() !== 'web') {
      Haptics.notification({
        type: NotificationType.Error,
      })
        .then()
        .catch(console.log);
    }
    this.errorMessage = er instanceof DineEngineError ? er.message : this.errorService.checkoutError(er as HttpErrorResponse);
    this.toast.danger(this.errorMessage);
    this.promoLoading = false;
    this.isLoading = false;
  }

  storeAddresses() {
    if (this.displayUser && !this.displayUser.isGuest) {
      if (this.orderType === 2 || this.orderType === 3) {
        this.userLocation.storeSavedAddress(this.displayOrder.deliveryAddress, 'delivery', this.displayOrder.location);
      } else {
        this.userLocation.storeSavedAddress(this.displayOrder.location.address, 'pickup', this.displayOrder.location);
      }
      const savedAddress = this.userLocation.getSavedAddresses();
      if (savedAddress && savedAddress.length) {
        this.store.dispatch(new SetUserSavedAddress(savedAddress));
      }
    }
  }

  giftCardTotal() {
    let total = 0;
    this.giftCards.forEach(res => {
      total = total + res.balanceCents;
    });
    return total;
  }

  // async scan() {
  //   if (this.canScan) {
  //     const options = {
  //       requireExpiry: true,
  //       requireCVV: true,
  //       requirePostalCode: true,
  //     };
  //     const cardResponse = await this.cardIO.scan(options);
  //     const cccardInfo = {
  //       cardnumber: cardResponse.cardNumber, // '4111111111111111',
  //       cardexp: cardResponse.expiryMonth + '/' + cardResponse.expiryYear, // '02/2020',
  //       cardcvv: cardResponse.cvv, // '202',
  //       cardzip: cardResponse.postalCode, // '43201'
  //     };
  //   }
  // }

  backToMenu() {
    this.navigation.navigateToMenuPage(this.menuService.restaurantSlug);
  }

  navigateToConfirmation(confirmationGuid: string) {
    this.modalService.dismissAll();
    sessionStorage.setItem('previousOrder', JSON.stringify(this.displayOrder));
    sessionStorage.removeItem('giftCards');
    this.store.dispatch(new ClearOrder());
    this.navigation.navigateToConfirmationPage(confirmationGuid);
  }

  addTip(tipSelected: number | 'custom') {
    this.tipLoading = true;
    if (tipSelected === 'custom') {
      this.selectedTip = null;
      const tipAmount = this.tipForm.get('tipAmount').value;
      if (tipAmount) {
        const replaceDollar = tipAmount.replace('$', '');
        this.store.dispatch(new AddTip(replaceDollar));
      }
    } else {
      this.selectedTip = tipSelected;
      const tipAmount = this.displayOrder?.subTotalCents ? (Number(this.displayOrder.subTotalCents) / 100) * (tipSelected / 100) : 0;
      this.store.dispatch(new AddTip(tipAmount));
    }
  }

  addPromoCode() {
    this.errorMessage = '';
    this.promoLoading = true;
    const promoCode = this.promoCodeForm.get('promoCode').value;
    if (promoCode) {
      this.store
        .dispatch(new AddPromoCode(promoCode))
        .toPromise()
        .then(() => (this.promoLoading = false))
        .catch(error => {
          this.errorMessage = error?.error?.message ?? error.message;
          this.toast.danger(this.errorMessage);
          this.promoLoading = false;
        });
    } else {
      this.promoLoading = false;
    }
  }

  removePromoCode() {
    this.promoLoading = true;
    this.store
      .dispatch(new RemovePromoCode())
      .toPromise()
      .then(() => (this.promoLoading = false));
  }

  applyGiftCard(giftCardNumber: number, isSaved = false, isLoyalty?: boolean) {
    if (giftCardNumber === 1) {
      this.giftCard1Loading = true;
    } else {
      this.giftCard2Loading = true;
    }
    let giftCardValue;
    let pinValue;
    if (this.hideGiftCardPin) {
      giftCardValue = this.giftCardForm.get('gcNumber' + giftCardNumber + 'NoPin').value;
      pinValue = null;
    } else {
      giftCardValue = this.giftCardForm.get('gcNumber' + giftCardNumber + 'Pin').value;
      pinValue = this.giftCardForm.get('gcPin' + giftCardNumber).value;
    }
    if (isLoyalty) {
      this.pageService.retrieveLoyaltyGiftCard().subscribe(loyaltyGC => {
        this.pageService.checkGiftCardBalance(loyaltyGC.cardNumber, loyaltyGC.cardPin).subscribe((giftCard: GiftCardItem) => {
          if (giftCard) {
            this.giftCard1Loading = false;
            this.giftCard2Loading = false;
            if (giftCard.balanceCents > 0) {
              if (!this.giftCards.find(gc => gc.cardNumber === giftCard.cardNumber)) {
                this.giftCards.push(giftCard);
                sessionStorage.setItem('giftCards', JSON.stringify(this.giftCards));
              } else {
                this.errorMessage = 'This gift card is already applied to your basket.';
                this.toast.danger(this.errorMessage);
              }
            } else {
              this.errorMessage = giftCard.message ? giftCard.message : 'There was an error processing your gift card.';
              this.toast.danger(this.errorMessage);
            }
          }
        });
      });
    } else if (isSaved) {
      // tslint:disable-next-line:max-line-length
      const giftCard = this.displaySavedGiftCards.find(
        card => String(card.savedCardID) === String(this.giftCardForm.get('savedGiftCard' + giftCardNumber + 'ID').value)
      );
      if (giftCard) {
        if (!this.giftCards.find(gc => String(gc.cardNumber) === String(giftCard.lastFourDigits))) {
          this.giftCards.push({
            balanceCents: giftCard.balanceCents,
            cardNumber: giftCard.lastFourDigits,
            pinNumber: '000000',
            isSaved: true,
            savedCardID: String(giftCard.savedCardID),
          });
          this.giftCardForm.get('gcNumber' + giftCardNumber + 'Pin').patchValue(this.giftCardMask);
          this.giftCardForm.get('gcNumber' + giftCardNumber + 'NoPin').patchValue(this.giftCardMask);
          this.giftCardForm.get('gcPin' + giftCardNumber).patchValue('000000');
          this.giftCardForm.get('saveGC' + giftCardNumber).patchValue(false);
          this.giftCardForm.get('saveGC' + giftCardNumber).disable();
          sessionStorage.setItem('giftCards', JSON.stringify(this.giftCards));
          this.giftCard1Loading = false;
          this.giftCard2Loading = false;
        } else {
          this.errorMessage = 'This gift card is already applied to your basket.';
          this.toast.danger(this.errorMessage);
        }
      } else {
        this.errorMessage = 'There was an error processing your gift card.';
        this.toast.danger(this.errorMessage);
      }
    } else {
      this.pageService
        .checkGiftCardBalance(giftCardValue, pinValue)
        .pipe(take(1))
        .subscribe(
          (giftCard: GiftCardItem) => {
            if (giftCard) {
              this.giftCard1Loading = false;
              this.giftCard2Loading = false;
              if (giftCard.balanceCents > 0) {
                if (!this.giftCards.find(gc => gc.cardNumber === giftCard.cardNumber)) {
                  this.giftCards.push(giftCard);
                  sessionStorage.setItem('giftCards', JSON.stringify(this.giftCards));
                } else {
                  this.errorMessage = 'This gift card is already applied to your basket.';
                  this.toast.danger(this.errorMessage);
                  if (giftCardNumber === 1) {
                    this.giftCard1Loading = false;
                  } else {
                    this.giftCard2Loading = false;
                  }
                }
              } else {
                this.errorMessage = giftCard.message ? giftCard.message : 'There was an error processing your gift card.';
                this.toast.danger(this.errorMessage);
                if (giftCardNumber === 1) {
                  this.giftCard1Loading = false;
                } else {
                  this.giftCard2Loading = false;
                }
              }
            }
          },
          () => {
            this.errorMessage = 'There was an error processing your gift card.';
            this.toast.danger(this.errorMessage);
            if (giftCardNumber === 1) {
              this.giftCard1Loading = false;
            } else {
              this.giftCard2Loading = false;
            }
          }
        );
    }
  }

  removeGiftCard(giftCardIndex: number) {
    if (this.giftCards[giftCardIndex] && this.giftCards[giftCardIndex].isSaved) {
      this.giftCardForm.patchValue(giftCardIndex ? { savedGiftCard2ID: null } : { savedGiftCard1ID: null });
      this.giftCardForm.patchValue(giftCardIndex ? { saveGC2: false } : { saveGC1: false });
      switch (giftCardIndex) {
        case 0:
          this.giftCardForm.get('saveGC1').enable();
          break;
        case 1:
          this.giftCardForm.get('saveGC2').enable();
          break;
      }
    }
    this.giftCards.splice(giftCardIndex, 1);
    sessionStorage.setItem('giftCards', JSON.stringify(this.giftCards));
    this.setupGiftCards();
  }

  applyLoyaltyCard() {
    this.pageService.retrieveLoyaltyGiftCard().subscribe(loyaltyGC => {
      this.pageService.checkGiftCardBalance(loyaltyGC.cardNumber, loyaltyGC.cardPin).subscribe((giftCard: GiftCardItem) => {
        if (giftCard) {
          if (giftCard.balanceCents > 0) {
            this.appliedLoyaltyBalance = {
              balanceCents: giftCard.balanceCents,
              cardNumber: loyaltyGC.cardNumber,
              cardPin: loyaltyGC.cardPin,
            };
          } else {
            this.errorMessage = giftCard.message ? giftCard.message : 'There was an error processing your gift card.';
            this.toast.danger(this.errorMessage);
          }
        }
      });
    });
  }

  removeLoyaltyCard() {
    this.appliedLoyaltyBalance = {
      balanceCents: 0,
      cardNumber: null,
      cardPin: null,
    };
  }
  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('password2').value ? null : { mismatch: true };
  }

  changePickupMethod(method: number) {
    const currentHandoffType = this.orderType;
    this.loadingHandoff = method;
    this.orderType = method;
    this.tempOrderType = method;
    this.store.dispatch(new SetHandoffType(method)).subscribe(
      () => {
        this.errorMessage = '';
      },
      (error: any) => {
        this.errorMessage = error.error.message;
        const invalidProductsNames = error.error.message.split(' are not available for ')[0].split(', ');
        const modalRef = this.modalService.open(RemoveInvalidProductsComponent, { centered: true });
        modalRef.componentInstance.invalidProducts = invalidProductsNames;
        modalRef.componentInstance.handoffMode = HandoffType[this.orderType];
        modalRef.componentInstance.cancelEmit.subscribe(() => {
          this.tempOrderType = -1;
          this.orderType = currentHandoffType;
          this.loadingHandoff = HandoffType.loading;
        });
        modalRef.componentInstance.continueEmit.subscribe(() => {
          const invalidProducts = this.displayOrder.items.filter((orderItem: OrderItem) => invalidProductsNames.includes(orderItem.name));
          forkJoin(
            invalidProducts.map((orderItem: OrderItem) => {
              this.cartProductLoading.push(orderItem.orderItemID.toString());
              return this.store.dispatch(new RemoveFromOrder(orderItem)).pipe(
                map(() => {
                  return this.cartProductLoading.splice(this.cartProductLoading.indexOf(orderItem.orderItemID, 1));
                })
              );
            })
          ).subscribe(() => {
            this.loadingHandoff = HandoffType.loading;
            this.store.dispatch(new ValidateOrder());
            this.store.dispatch(new SetHandoffType(method)).subscribe(() => {
              this.tempOrderType = -1;
            });
          });
        });
      }
    );
  }

  changePaymentMethod(method: number) {
    this.paymentTouched = true;
    if (method === PaymentTypes.creditCard && this.selectedCardID) {
      this.paymentType = PaymentTypes.savedCard;
      return;
    }
    this.paymentType = method;
  }

  addGiftCard(method: number) {
    this.giftCard = method;
  }

  switchToDelivery() {
    this.promoCodeForm.get('promoCode').disable();
    this.navigation.navigateToDeliveryLocationsPage();
  }

  switchToPickup() {
    this.navigation.navigateToPickupLocationsPage();
  }

  redeemedReward(reward: Reward) {
    this.errorMessage = '';
    this.loadingReward = reward.rewardID;
    this.areRewardsSelecting = true;
    this.analytics.analyticsOnRewardSelect(
      reward,
      this.displayRewards.indexOf(reward),
      this.store.selectSnapshot((state: GlobalStateModel) => state.order.order).location
    );
    this.store.dispatch(new StageReward(reward));
  }

  removedReward(reward: Reward) {
    this.errorMessage = '';
    this.loadingReward = reward.rewardID;
    this.areRewardsSelecting = true;
    this.store
      .dispatch(new RemoveReward(reward))
      .toPromise()
      .then(() => {
        // this.store
        //   .dispatch(new UpdateAppliedRewards(reward))
        //   .toPromise()
        //   .then(() => {
        this.loadingReward = null;
        this.areRewardsSelecting = false;
        // });
      })
      .catch(er => {
        this.loadingReward = null;
        this.errorMessage = er instanceof DineEngineError ? er.message : this.errorService.checkoutError(er as HttpErrorResponse);
        this.toast.danger(this.errorMessage);
        this.areRewardsSelecting = false;
      });
  }

  requestToken() {
    if (this.isLoading) {
      return;
    }
    this.errorMessage = '';
    const mode = sessionStorage.getItem('mode');
    if (mode === 'tableside') {
      if (this.providerName === 'olo') {
        if (this.displayUser?.isGuest || this.displayGuestUser?.isGuest) {
          if (this.emailAddress.invalid || this.phoneNumber.invalid) {
            this.errorMessage = 'Please fill out all contact info';
            this.toast.danger('Please fill out all contact info');
            this.userForm.markAllAsTouched();
            return;
          }
        }
      }
    } else {
      this.favoriteLocation?.clearValidators();
      if (this.modeService.mode !== 'kiosk') {
        if (this.signUpOptIn.value === true && (this.displayUser?.isGuest || this.displayGuestUser?.isGuest)) {
          // And not logged in
          this.password.setValidators(Validators.required);
          this.password.setValidators(this.passwordValidator());
          this.password2.setValidators(Validators.required);
          this.password2.setValidators(this.passwordValidator());
          if (this.loyaltyLocations !== null) {
            this.favoriteLocation.setValidators(Validators.required);
          }
          this.birthday.setValidators([Validators.required, Validators.minLength(8)]);
          if (this.password.value !== this.password2.value) {
            this.toast.danger('Passwords do not match');
            return;
          }
          // tslint:disable-next-line:forin
          for (const control in this.userForm.controls) {
            this.userForm.get(control).updateValueAndValidity();
          }
          this.userForm.markAllAsTouched();
        }
      }

      this.favoriteLocation?.updateValueAndValidity();
      this.userForm.updateValueAndValidity();
      if (this.modeService.mode !== 'kiosk') {
        if (this.displayGuestUser && (this.userForm.invalid || (this.additionalFieldsForm.invalid && this.signUpOptIn.value === true))) {
          this.errorMessage = 'Please fill out all contact info';
          this.toast.danger('Please fill out all contact info');
          this.userForm.markAllAsTouched();
          this.additionalFieldsForm.markAllAsTouched();
          return;
          // } else {
          //   if (this.signUpOptIn.value === true) { // And not logged in
          //     this.isLoading = true;
          //     const newAccount: NewAccount = {
          //       email: this.emailAddress.value,
          //       dob: moment(this.birthday.value).format('YYYY-MM-DD'),
          //       firstName: this.firstName.value,
          //       lastName: this.lastName.value,
          //       password: this.password.value,
          //       phone: this.phoneNumber.value,
          //       emailOptIn: this.guestOptIn.value,
          //       smsOptIn: false,
          //     };
          //     if (this.loyaltyLocations) {
          //       newAccount.favoriteLocation = this.favoriteLocation.value;
          //     }
          //     this.store.dispatch(new CreateAccount(newAccount)).toPromise().then((state: any) => {
          //       // this.capacitorIntegration.setRadarUserID(state.user.userID);
          //     }).catch((er) => {
          //       this.isLoading = false;
          //       if (er.message) {
          //         this.errorMessage = er.message;
          //       } else {
          //         this.errorMessage = this.errorService.registerError(er as HttpErrorResponse);
          //       }
          //       return;
          //     });
          //   }
        }
      }
    }
    // if (!this.displayOrder.location.isOpen && this.displayOrder.isASAP) {
    //   this.errorMessage =
    //     'The restaurant is currently closed. Please select a future time for your order.';
    //   return;
    // }
    if (this.isLoading || this.loadingTimeMode) {
      return;
    }
    if (this.modeService.mode !== 'kiosk') {
      if (this.signUpOptIn.value && (this.displayUser?.isGuest || this.displayGuestUser?.isGuest)) {
        if (moment(this.birthday.value, 'MMDDYYYY').isAfter(moment(this.earliestBDay))) {
          this.errorMessage = 'You must be 13 or older to create an account';
          this.toast.danger('You must be 13 or older to create an account');
          return;
        }
        this.isLoading = true;
        const newAccount: NewAccount = {
          email: this.emailAddress.value,
          dob: moment(this.birthday.value, 'MMDDYYYY').format('YYYY-MM-DD'),
          firstName: this.firstName.value,
          lastName: this.lastName.value,
          password: this.password.value,
          phone: this.phoneNumber.value,
          emailOptIn: this.userForm.get('emailOptIn').value,
          smsOptIn: this.userForm.get('smsOptIn').value,
        };
        if (this.loyaltyLocations) {
          newAccount.favoriteLocation = this.favoriteLocation.value;
        }
        const additionalFields = this.store
          .selectSnapshot((state: GlobalStateModel) => state.user.additionalUserFields)
          .map(field => {
            return {
              ...field,
              value: this.additionalFieldsForm.get(field.providerFieldName).value,
            };
          });
        this.store
          .dispatch(new CreateAccount(newAccount, additionalFields))
          .toPromise()
          .then(() => {
            this.finishSubmit();
          })
          .catch(er => {
            this.isLoading = false;
            this.errorMessage = this.errorService.registerError(er as HttpErrorResponse);
            this.toast.danger(this.errorMessage);
            return;
          });
      } else {
        this.finishSubmit();
      }
    } else {
      this.finishSubmit();
    }
  }

  finishSubmit() {
    if (this.paymentProcessor === 'datacap') {
      DatacapWebToken.requestToken(this.datacapWebToken, 'datacap_form', this.tokenCallback);
    } else if (this.paymentType === PaymentTypes.applePay || this.paymentType === PaymentTypes.googlePay) {
      OloPay.getDigitalWalletPaymentMethod({
        amount: Number(
          (Math.round(this.displayOrder.totalCents - (this.giftCardTotal() + this.appliedLoyaltyBalance.balanceCents)) / 100).toFixed(2)
        ),
        countryCode: this.displayOrder.location.address.country ?? 'US',
        currencyCode: this.store.selectSnapshot(state => state.order.currencyCode),
      }).then(paymentResponse => {
        this.paymentMethod = {
          schemeID: 1,
          token: paymentResponse.paymentMethod.id,
          cardType: paymentResponse.paymentMethod.cardType,
          cardLastFour: paymentResponse.paymentMethod.last4,
          streetAddress: '',
          streetAddress2: '',
          zipCode: paymentResponse.paymentMethod.postalCode,
          countryCode: paymentResponse.paymentMethod.countryCode,
          expirationMonth: paymentResponse.paymentMethod.expMonth,
          expirationYear: paymentResponse.paymentMethod.expYear,
          isDigitalWallet: paymentResponse.paymentMethod.isDigitalWallet,
        };
        this.paymentType = PaymentTypes.creditCardToken;
        this.paymentTouched = true;
        this.submit();
      });
    } else if (
      this.paymentProcessor === 'olo-pay' &&
      this.displayOrder.supportsOloPay &&
      this.paymentType === PaymentTypes.creditCard &&
      this.displayOrder.totalCents > 0 &&
      Capacitor.getPlatform() === 'web'
    ) {
      this.oloPayService
        .tokenizeSingleField()
        .pipe(take(1))
        .subscribe(
          (paymentMethod: any) => {
            this.paymentMethod = paymentMethod;
            this.paymentType = PaymentTypes.creditCardToken;
            this.paymentTouched = true;
            this.submit();
          },
          (error: Error) => {
            this.errorMessage = error.message;
            this.toast.danger(error.message);
          }
        );
    } else if (
      this.paymentType === PaymentTypes.giftCard &&
      (((!this.giftCards || !this.giftCards.length) && this.displayOrder.location.supportsSplitPayments) ||
        (!this.displayOrder.location.supportsSplitPayments && !this.singleGiftCardForm.get('gcNumber').value))
    ) {
      this.errorMessage = 'Please enter a gift card number';
      this.toast.danger(this.errorMessage);
      this.isLoading = false;
      this.submitting = false;
    } else {
      this.submit();
    }
  }

  birthDayChange(nextElement) {
    this.errorMessage = '';
    nextElement.setFocus();
  }

  setCustomFieldDateValue(event: IonDatetimeCustomEvent<HTMLInputElement>, field: UserField) {
    const fullDateTimeString = event?.detail?.value;

    if (fullDateTimeString) {
      // Parse the original date string
      const dateObject = new Date(fullDateTimeString);

      // Extract date components
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
      const day = ('0' + dateObject.getDate()).slice(-2);

      // Format the date as MM-DD-YYYY
      const reformattedDate = `${month}${day}${year}`;

      // Set the reformatted date to your form control or variable
      this.additionalFieldsForm.controls[field.providerFieldName].setValue(reformattedDate);
    } else {
      console.error('Invalid or undefined date value');
    }
  }

  changeFavoriteLocation(event: any) {}

  populateSavedCard(event) {
    if (event.target.value === 'addNewCard') {
      this.selectedCardID = null;
      this.addNewCardClicked();
    } else {
      this.addCardClicked = false;
      this.creditCardForm.reset();
      this.paymentType = PaymentTypes.savedCard;
      this.selectedCardID = parseInt(event.target.value, 10);
    }
  }

  isMultiplePayment() {
    let isMultiPay = false;
    if (
      (this.giftCards && this.giftCards.length > 1) ||
      (Number(this.giftCardTotal()) < this.displayOrder.totalCents &&
        // tslint:disable-next-line:max-line-length
        this.giftCards.length > 0) ||
      (this.appliedLoyaltyBalance.balanceCents > 0 && this.appliedLoyaltyBalance.balanceCents < this.displayOrder.totalCents)
    ) {
      isMultiPay = true;
    }
    return isMultiPay;
  }

  getMultiBillingAccounts(): MultiPayDetails[] {
    const details: MultiPayDetails[] = [];
    // add loyalty balance
    if (this.appliedLoyaltyBalance && this.appliedLoyaltyBalance.balanceCents) {
      const cardDetails: MultiPayDetails = {
        billingmethod: null,
        // tslint:disable-next-line:max-line-length
        amount: Math.round(
          this.displayOrder.totalCents - this.giftCardTotal() > this.appliedLoyaltyBalance.balanceCents
            ? this.appliedLoyaltyBalance.balanceCents
            : this.displayOrder.totalCents - this.giftCardTotal()
        ),
        tipportion: Math.round(
          Number(
            // tslint:disable-next-line:max-line-length
            this.displayOrder.tipCents *
              ((this.displayOrder.totalCents - this.giftCardTotal() > this.appliedLoyaltyBalance.balanceCents
                ? this.appliedLoyaltyBalance.balanceCents
                : this.displayOrder.totalCents - this.giftCardTotal()) /
                this.displayOrder.totalCents)
          )
        ),
        cardnumber: null,
        expiryyear: null,
        expirymonth: null,
        cvv: null,
        streetaddress: null,
        streetaddress2: null,
        city: null,
        state: null,
        zip: null,
        country: null,
        saveonfile: 'false',
        savedcardid: null,
        billingaccountid: null,
        billingschemeid: null,
        billingfields: [
          {
            name: 'number',
            value: this.appliedLoyaltyBalance.cardNumber,
          },
          {
            name: 'pin',
            value: this.appliedLoyaltyBalance.cardPin,
          },
        ],
      };
      details.push(cardDetails);
    }
    // Add Gift Cards
    this.giftCards.forEach((gc, i) => {
      if (this.giftCards.length === 2 && i === 0) {
        const cardDetails: MultiPayDetails = {
          billingmethod: null,
          amount: Math.round(gc.balanceCents),
          tipportion: Math.round(Number(this.displayOrder.tipCents * (gc.balanceCents / this.displayOrder.totalCents))),
          cardnumber: null,
          expiryyear: null,
          expirymonth: null,
          cvv: null,
          streetaddress: null,
          streetaddress2: null,
          city: null,
          state: null,
          zip: null,
          country: null,
          saveonfile: this.giftCardForm.get('saveGC1').value,
          savedcardid: gc.isSaved ? Number(gc.savedCardID) : null,
          billingaccountid: gc.isSaved ? Number(gc.savedCardID) : null,
          billingschemeid: null,
          billingfields: gc.isSaved
            ? null
            : [
                {
                  name: 'number',
                  value: gc.cardNumber,
                },
                {
                  name: 'pin',
                  value: gc.pinNumber,
                },
              ],
        };
        details.push(cardDetails);
      } else if (this.giftCards.length === 2 && i === 1) {
        const cardDetails: MultiPayDetails = {
          billingmethod: null,
          // tslint:disable-next-line:max-line-length
          amount: Math.round(
            this.displayOrder.totalCents - this.displayOrder.tipCents - this.giftCards[0].balanceCents < gc.balanceCents
              ? this.displayOrder.totalCents - this.displayOrder.tipCents - this.giftCards[0].balanceCents
              : gc.balanceCents
          ),
          // tslint:disable-next-line:max-line-length
          tipportion: Math.round(
            Number(
              this.displayOrder.tipCents *
                ((this.displayOrder.totalCents - this.giftCards[0].balanceCents < gc.balanceCents
                  ? this.displayOrder.totalCents - this.giftCards[0].balanceCents
                  : gc.balanceCents) /
                  this.displayOrder.totalCents)
            )
          ),
          cardnumber: null,
          expiryyear: null,
          expirymonth: null,
          cvv: null,
          streetaddress: null,
          streetaddress2: null,
          city: null,
          state: null,
          zip: null,
          country: null,
          saveonfile: this.giftCardForm.get('saveGC2').value,
          savedcardid: gc.isSaved ? Number(gc.savedCardID) : null,
          billingaccountid: gc.isSaved ? Number(gc.savedCardID) : null,
          billingschemeid: null,
          billingfields: gc.isSaved
            ? null
            : [
                {
                  name: 'number',
                  value: gc.cardNumber,
                },
                {
                  name: 'pin',
                  value: gc.pinNumber,
                },
              ],
        };
        details.push(cardDetails);
      } else {
        const cardDetails: MultiPayDetails = {
          billingmethod: null,
          // tslint:disable-next-line:max-line-length
          amount: Math.round(this.displayOrder.totalCents < gc.balanceCents ? this.displayOrder.totalCents : gc.balanceCents),
          tipportion: Math.round(
            this.displayOrder.totalCents < gc.balanceCents
              ? this.displayOrder.tipCents
              : Number(this.displayOrder.tipCents * (gc.balanceCents / this.displayOrder.totalCents))
          ),
          cardnumber: null,
          expiryyear: null,
          expirymonth: null,
          cvv: null,
          streetaddress: null,
          streetaddress2: null,
          city: null,
          state: null,
          zip: null,
          country: null,
          saveonfile: this.giftCardForm.get('saveGC1').value,
          savedcardid: gc.isSaved ? Number(gc.savedCardID) : null,
          billingaccountid: gc.isSaved ? Number(gc.savedCardID) : null,
          billingschemeid: null,
          billingfields: gc.isSaved
            ? null
            : [
                {
                  name: 'number',
                  value: gc.cardNumber,
                },
                {
                  name: 'pin',
                  value: gc.pinNumber,
                },
              ],
        };
        details.push(cardDetails);
      }
    });
    // Add Credit Card or saved card
    if (this.giftCardTotal() < this.displayOrder.totalCents) {
      if (this.paymentType === PaymentTypes.giftCard) {
        if (this.selectedCardID) {
          this.paymentType = PaymentTypes.savedCard;
        } else {
          this.paymentType = this.displayOrder.supportedPaymentTypes.includes(PaymentTypes.creditCardToken)
            ? PaymentTypes.creditCardToken
            : PaymentTypes.creditCard;
        }
      }
      let gcTotal = 0;
      let gcTip = 0;
      details.forEach(card => {
        gcTotal += card.amount;
        gcTip += card.tipportion;
      });
      const cardTotal = Math.round(this.displayOrder.totalCents - gcTotal);
      const cardTip = Math.round(this.displayOrder.tipCents - gcTip);
      if (this.paymentType === PaymentTypes.creditCard) {
        // credit card
        const expStr = String(this.creditCardForm.get('ccExpiration').value);
        const zip = this.creditCardForm.get('ccZip').value.replace(' ', '');
        const cardDetails: MultiPayDetails = {
          billingmethod: null,
          // tslint:disable-next-line:max-line-length
          amount: cardTotal > 0 ? cardTotal : 0,
          tipportion: cardTip > 0 ? cardTip : 0,
          cardnumber: String(this.creditCardForm.get('ccNumber').value),
          expiryyear: Number(expStr.split('/')[1]),
          expirymonth: Number(expStr.split('/')[0]),
          cvv: String(this.creditCardForm.get('ccCVV').value),
          streetaddress: this.creditCardForm.get('ccStreetAddress').value,
          streetaddress2: this.creditCardForm.get('ccStreetAddress2').value,
          city: this.creditCardForm.get('ccCity').value,
          state: this.addressService.getStateCodeFromZip(zip),
          zip,
          country: null,
          saveonfile: this.creditCardForm.get('ccSave').value,
          billingaccountid: null,
          billingschemeid: null,
          billingfields: null,
          savedcardid: null,
        };
        details.push(cardDetails);
      } else if (this.paymentType === PaymentTypes.savedCard) {
        // saved card
        const cardDetails: MultiPayDetails = {
          billingmethod: null,
          // tslint:disable-next-line:max-line-length
          amount: cardTotal > 0 ? cardTotal : 0,
          tipportion: cardTip > 0 ? cardTip : 0,
          cardnumber: null,
          expiryyear: null,
          expirymonth: null,
          cvv: this.showSavedCardCVV ? String(this.savedCreditCardForm.get('scCVV').value) : null,
          streetaddress: null,
          streetaddress2: null,
          city: null,
          state: null,
          zip: null,
          country: null,
          saveonfile: null,
          billingaccountid: null,
          billingschemeid: null,
          billingfields: null,
          savedcardid: this.selectedCardID ? this.selectedCardID : null,
        };
        details.push(cardDetails);
      } else if (this.paymentType === PaymentTypes.creditCardToken) {
        const cardDetails: MultiPayDetails = {
          billingmethod: null,
          amount: cardTotal > 0 ? cardTotal : 0,
          tipportion: cardTip > 0 ? cardTip : 0,
          cardnumber: null,
          expiryyear: this.paymentMethod.expirationYear,
          expirymonth: this.paymentMethod.expirationMonth,
          cvv: null,
          streetaddress: null,
          streetaddress2: null,
          city: null,
          state: null,
          zip: this.paymentMethod.zipCode,
          country: null,
          saveonfile: null,
          billingaccountid: null,
          billingschemeid: null,
          billingfields: null,
          savedcardid: null,
          token: this.paymentMethod.token,
          cardtype: this.paymentMethod.cardType,
          cardlastfour: this.paymentMethod.cardLastFour,
          isDigitalWallet: !!this.paymentMethod.isDigitalWallet,
          digitalWalletType: this.paymentMethod.digitalWalletType,
        };
        details.push(cardDetails);
      }
    }
    return details;
  }

  scrollToCenter(id: string) {
    this.utility.scrollToCenter(id);
  }

  openAgeCheckModal() {
    this.modalService.dismissAll();
    this.modalService.open(AgeCheckComponent, { centered: true });
  }

  private collectCardDetails(): CardDetails {
    let firstName = this.displayUser ? this.displayUser.firstName : this.userForm.get('firstName').value;
    let lastName = this.displayUser ? this.displayUser.lastName : this.userForm.get('lastName').value || '';
    const emailAddress = this.displayUser ? this.displayUser.email : this.userForm.get('emailAddress').value || '';
    const phoneNumber = this.displayUser ? this.displayUser.phoneNumber : this.userForm.get('phoneNumber').value || '';
    if (this.modeService.mode === 'tableside') {
      if (firstName === '') {
        if (!sessionStorage.getItem('tablesideGuest')) {
          this.userForm.markAllAsTouched();
          this.isLoading = false;
          this.errorMessage = 'Please enter your name';
          throw new Error('Please enter your name');
        }
        firstName = sessionStorage.getItem('tablesideGuest');
      }
      lastName = 'Table#: '.concat(sessionStorage.getItem('tablenumber'));
      if (emailAddress === '') {
        this.userForm.markAllAsTouched();
        this.isLoading = false;
        this.errorMessage = 'Please enter your email';
        throw new Error('Please enter your email');
      }
      if (phoneNumber === '') {
        this.userForm.markAllAsTouched();
        this.isLoading = false;
        this.errorMessage = 'Please enter your phone number';
        throw new Error('Please enter your phone number');
      }
    }

    if (this.displayOrder.totalCents === 0 || this.paymentType === PaymentTypes.cash) {
      return {
        firstName,
        lastName,
        emailAddress,
        billingAddress: null,
        token: null,
        cardType: null,
        cardLastFour: null,
        cardNumber: null,
        cvv: null,
        savedCardID: null,
        giftCardNumber: null,
        pin: null,
        company: null,
        expirationMonth: null,
        expirationYear: null,
        phoneNumber,
        isGiftCard: false,
        isSavedCard: false,
        paymentType: PaymentTypes.cash,
        guestOptIn: this.userForm.get('guestOptIn').value,
        isMultiPayment: false,
        multiPaymentBillingAccounts: null,
      };
    }
    if (this.isMultiplePayment()) {
      return {
        firstName,
        lastName,
        emailAddress,
        billingAddress: null,
        token: null,
        cardType: null,
        cardLastFour: null,
        cardNumber: null,
        cvv: this.showSavedCardCVV ? String(this.savedCreditCardForm.get('scCVV').value) : null,
        savedCardID: this.selectedCardID ? this.selectedCardID : null,
        giftCardNumber: null,
        pin: null,
        company: null,
        expirationMonth: null,
        expirationYear: null,
        phoneNumber: this.userForm.get('phoneNumber').value,
        isGiftCard: false,
        isSavedCard: true,
        paymentType: PaymentTypes.creditCard,
        guestOptIn: this.userForm.get('guestOptIn').value,
        isMultiPayment: true,
        multiPaymentBillingAccounts: this.getMultiBillingAccounts(),
      };
    } else if (this.paymentType === PaymentTypes.savedCard) {
      if (this.showSavedCardCVV && !this.savedCreditCardForm.get('scCVV').value) {
        this.toast.danger('CVV is required');
        throw new Error('CVV is required');
      }
      // Saved card
      return {
        firstName,
        lastName,
        emailAddress,
        billingAddress: null,
        token: null,
        cardType: null,
        cardLastFour: null,
        cardNumber: null,
        cvv: this.showSavedCardCVV ? String(this.savedCreditCardForm.get('scCVV').value) : null,
        savedCardID: this.selectedCardID ? this.selectedCardID : null,
        giftCardNumber: null,
        pin: null,
        company: null,
        expirationMonth: null,
        expirationYear: null,
        phoneNumber: this.userForm.get('phoneNumber').value,
        isGiftCard: false,
        isSavedCard: true,
        paymentType: PaymentTypes.savedCard,
        guestOptIn: this.userForm.get('guestOptIn').value,
        isMultiPayment: false,
        multiPaymentBillingAccounts: null,
      };
    } else if (this.paymentType === PaymentTypes.loyaltyStoredValue) {
      return {
        firstName,
        lastName,
        emailAddress,
        billingAddress: null,
        token: null,
        cardType: null,
        cardLastFour: null,
        cardNumber: null,
        cvv: null,
        savedCardID: this.selectedCardID ? this.selectedCardID : null,
        giftCardNumber: this.appliedLoyaltyBalance.cardNumber,
        pin: this.appliedLoyaltyBalance.cardPin,
        company: null,
        expirationMonth: null,
        expirationYear: null,
        phoneNumber: this.userForm.get('phoneNumber').value,
        isGiftCard: true,
        isSavedCard: false,
        paymentType: PaymentTypes.giftCard,
        guestOptIn: this.userForm.get('guestOptIn').value,
        isMultiPayment: false,
        multiPaymentBillingAccounts: null,
      };
    } else if (this.paymentType === PaymentTypes.giftCard) {
      // Gift card
      return {
        firstName,
        lastName,
        emailAddress,
        billingAddress: null,
        cardNumber: null,
        token: null,
        cardType: null,
        cardLastFour: null,
        cvv: null,
        // tslint:disable-next-line:max-line-length
        savedCardID: this.displayOrder.location.supportsSplitPayments
          ? this.giftCards[0].isSaved
            ? this.giftCards[0].savedCardID
            : null
          : this.singleGiftCardForm.get('savedGiftCardID').value
            ? this.singleGiftCardForm.get('savedGiftCardID').value
            : null,
        giftCardNumber: this.displayOrder.location.supportsSplitPayments
          ? this.giftCards[0].isSaved
            ? null
            : this.giftCards[0].cardNumber
          : this.singleGiftCardForm.get('savedGiftCardID').value
            ? null
            : this.singleGiftCardForm.get('gcNumber').value,
        // tslint:disable-next-line:max-line-length
        pin: this.displayOrder.location.supportsSplitPayments
          ? this.giftCards[0].isSaved
            ? null
            : this.giftCards[0].pinNumber
          : this.singleGiftCardForm.get('savedGiftCardID').value
            ? null
            : this.singleGiftCardForm.get('gcPin').value,
        company: null,
        expirationMonth: null,
        expirationYear: null,
        phoneNumber: this.userForm.get('phoneNumber').value,
        isGiftCard: this.paymentType === PaymentTypes.giftCard,
        isSavedCard: this.displayOrder.location.supportsSplitPayments
          ? !!this.giftCards[0].isSaved
          : !!this.singleGiftCardForm.get('savedGiftCardID').value,
        paymentType: PaymentTypes.giftCard,
        guestOptIn: this.userForm.get('guestOptIn').value,
        isMultiPayment: false,
        multiPaymentBillingAccounts: null,
      };
    } else if (this.paymentType === PaymentTypes.creditCard) {
      // Credit card
      const expStr = String(this.creditCardForm.get('ccExpiration').value);
      console.log(expStr);
      const zip = this.creditCardForm.get('ccZip').value.replace(' ', '');
      return {
        firstName,
        lastName,
        emailAddress,
        billingAddress: {
          address1: this.creditCardForm.get('ccStreetAddress').value ? this.creditCardForm.get('ccStreetAddress').value : 'N/A',
          address2: this.creditCardForm.get('ccStreetAddress2').value ? this.creditCardForm.get('ccStreetAddress2').value : '',
          city: this.creditCardForm.get('ccCity').value ? this.creditCardForm.get('ccCity').value : 'N/A',
          state: this.addressService.getStateFromZip(zip),
          country: this.ccBillingCountry.value,
          zipCode: zip,
          latitude: null,
          longitude: null,
        },
        token: null,
        cardType: null,
        cardLastFour: null,
        cardNumber: String(this.creditCardForm.get('ccNumber').value),
        cvv: String(this.creditCardForm.get('ccCVV').value),
        savedCardID: null,
        giftCardNumber: null,
        pin: null,
        company: null,
        expirationMonth: expStr.split('/')[0],
        expirationYear: expStr.split('/')[1],
        phoneNumber: this.userForm.get('phoneNumber').value,
        isGiftCard: false,
        isSavedCard: false,
        paymentType: PaymentTypes.creditCard,
        guestOptIn: this.userForm.get('guestOptIn').value,
        isMultiPayment: false,
        multiPaymentBillingAccounts: null,
      };
    } else if (this.paymentType === PaymentTypes.creditCardToken) {
      // Credit card token
      // const zip = this.creditCardForm.get('ccZip').value.replace(' ', '');
      return {
        firstName,
        lastName,
        emailAddress,
        billingAddress: {
          address1: this.creditCardForm.get('ccStreetAddress').value ? this.creditCardForm.get('ccStreetAddress').value : 'N/A',
          address2: this.creditCardForm.get('ccStreetAddress2').value ? this.creditCardForm.get('ccStreetAddress2').value : '',
          city: this.creditCardForm.get('ccCity').value ? this.creditCardForm.get('ccCity').value : 'N/A',
          state: this.addressService.getStateFromZip(this.paymentMethod.zipCode),
          country: this.ccBillingCountry.value,
          zipCode: this.paymentMethod.zipCode,
          latitude: null,
          longitude: null,
        },
        token: this.paymentMethod.token,
        cardType: this.paymentMethod.cardType,
        cardLastFour: this.paymentMethod.cardLastFour,
        cardNumber: null,
        cvv: null,
        savedCardID: null,
        giftCardNumber: null,
        pin: null,
        company: null,
        expirationMonth: this.paymentMethod.expirationMonth,
        expirationYear: this.paymentMethod.expirationYear,
        phoneNumber: this.userForm.get('phoneNumber').value,
        isGiftCard: false,
        isSavedCard: false,
        paymentType: PaymentTypes.creditCardToken,
        guestOptIn: this.userForm.get('guestOptIn').value,
        isMultiPayment: false,
        multiPaymentBillingAccounts: null,
        isDigitalWallet: !!this.paymentMethod.isDigitalWallet,
        digitalWalletType: this.paymentMethod.digitalWalletType,
      };
    }
  }

  facebookLogin() {
    this.loyaltyLoginModalRef.componentInstance.facebookLoading = true;
    const FACEBOOK_PERMISSIONS = ['email'];
    FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
      .then(result => {
        FacebookLogin.getCurrentAccessToken()
          .then(result2 => {
            // @ts-ignore
            FacebookLogin.getProfile<{ email: string }>({ fields: ['email'] })
              .then(result3 => {
                if (result2.accessToken.userId && result2.accessToken.token && result3.email) {
                  // tslint:disable-next-line:max-line-length
                  this.store
                    .dispatch(new SignInWithFacebook(result3.email, result2.accessToken.token, result2.accessToken.userId))
                    .toPromise()
                    .then(() => {
                      this.router
                        .navigate(['profile'])
                        .then(() => {
                          this.loyaltyLoginModalRef.componentInstance.facebookLoading = false;
                        })
                        .catch(error => {
                          this.loyaltyLoginModalRef.componentInstance.facebookLoading = false;
                          console.log(error);
                          this.loyaltyLoginModalRef.componentInstance.errorMessage = error;
                        });
                    })
                    .catch(error => {
                      this.loyaltyLoginModalRef.componentInstance.facebookLoading = false;
                      console.log(error);
                      this.loyaltyLoginModalRef.componentInstance.errorMessage = error;
                    });
                } else {
                  this.loyaltyLoginModalRef.componentInstance.errorMessage =
                    'There was an error retrieving your info from Facebook. Please try again later.';
                }
              })
              .catch(error => {
                this.loyaltyLoginModalRef.componentInstance.facebookLoading = false;
                console.log(error);
                this.loyaltyLoginModalRef.componentInstance.errorMessage = error;
              });
          })
          .catch(error => {
            this.loyaltyLoginModalRef.componentInstance.facebookLoading = false;
            console.log(error);
            this.loyaltyLoginModalRef.componentInstance.errorMessage = error;
          });
      })
      .catch(result => {
        console.error(result);
        this.loyaltyLoginModalRef.componentInstance.errorMessage = 'Facebook Login Error';
        this.loyaltyLoginModalRef.componentInstance.facebookLoading = false;
      });
  }

  signInWithApple() {
    this.loyaltyLoginModalRef.componentInstance.appleLoading = true;
    SignInWithApple.authorize(this.signInWithAppleOptions)
      .then((result: SignInWithAppleResponse) => {
        this.store
          .dispatch(new SignInWithAppleStateAction(result, this.signInWithAppleOptions.redirectURI))
          .toPromise()
          .then(() => {
            this.loyaltyLoginModalRef.componentInstance.appleLoading = false;
          })
          .catch(error => {
            console.log(error);
            this.loyaltyLoginModalRef.componentInstance.errorMessage =
              'There was an error signing you in with Apple. If you already have an account with the email that is connected to your Apple ID, please connect the accounts in your profile after logging in.';
            this.loyaltyLoginModalRef.componentInstance.appleLoading = false;
          });
      })
      .catch(error => {
        console.log(error);
        this.loyaltyLoginModalRef.componentInstance.appleLoading = false;
      });
  }

  closePayment() {
    this.cardTerminal.getService().subscribe(service => {
      if (service) {
        service.cancelTransaction();
      }
    });
    this.closeModals();
  }

  closeModals() {
    this.modalService.dismissAll();
  }

  runCardTerminalTransaction() {
    this.store.dispatch(new ValidateOrder()).subscribe(
      () => {
        this.cardTerminal.getService().subscribe(service => {
          if (service) {
            this.activeSection = this.kioskPaymentTypes.prepaid;
            service.processSale(this.displayOrder.totalCents).subscribe(
              transactionInfo => {
                const firstName = sessionStorage.getItem('kioskGuest') || '';
                const lastName = sessionStorage.getItem('kioskTable') ? `Table #: ${sessionStorage.getItem('kioskTable')}` : 'guest';
                const email = sessionStorage.getItem('kioskEmail') || 'guest@dineengine.io';
                const phoneNumber = sessionStorage.getItem('kioskPhoneNumber') || '0000000000';

                this.paymentType = PaymentTypes.prepaid;
                this.userForm.setValue({
                  firstName,
                  lastName,
                  emailAddress: email,
                  phoneNumber,
                  guestOptIn: false,
                  signUpOptIn: false,
                  emailOptIn: false,
                  smsOptIn: false,
                  password: '',
                  password2: '',
                  birthday: '',
                });

                const cardDetails: CardDetails = {
                  firstName: this.displayUser ? this.displayUser.firstName : firstName,
                  lastName: this.displayUser ? this.displayUser.lastName : lastName,
                  emailAddress: this.displayUser ? this.displayUser.email : email,
                  billingAddress: null,
                  token: null,
                  cardType: null,
                  cardLastFour: null,
                  cardNumber: null,
                  cvv: null,
                  savedCardID: null,
                  giftCardNumber: null,
                  pin: null,
                  company: null,
                  expirationMonth: null,
                  expirationYear: null,
                  phoneNumber: phoneNumber,
                  isGiftCard: false,
                  isSavedCard: false,
                  paymentType: PaymentTypes.prepaid,
                  guestOptIn: this.userForm.get('guestOptIn').value,
                  isMultiPayment: false,
                  multiPaymentBillingAccounts: null,
                  prepaidID: transactionInfo.id,
                  prepaidDescription: transactionInfo.description,
                };
                this.modalService.open(OrderSubmissionModalComponent, {
                  animation: true,
                  keyboard: false,
                  centered: true,
                  backdrop: 'static',
                  windowClass: 'order-submission-modal',
                });

                // service.refundTransaction(transactionInfo.id, this.displayOrder.totalCents).subscribe(() => this.toast.success('refunded'));
                this.store.dispatch(new SubmitPayment(cardDetails, null, null, false, null)).subscribe(
                  newState => {
                    sessionStorage.setItem('kioskPayment', 'prepaid');
                    this.navigateToConfirmation(newState.order.previousOrder.orderID);
                    this.modalService.dismissAll();
                  },
                  error => {
                    this.toast.danger(this.errorService.checkoutError(error));
                    this.modalService.dismissAll();
                  }
                );
              },
              error => {
                if (typeof error === 'string') {
                  if (error.startsWith('Action canceled')) {
                    this.store.dispatch(new ClearOrder());
                    this.navigation.navigateToKioskStartOrderPage(this.displayOrder.location.locationID);
                    return;
                  }
                  if (this.checkout_wizard === 'payment' && this.activeSection === 'selectPaymentType') {
                    this.goBackward();
                  } else {
                    this.activeSection = 'selectPaymentType';
                  }
                  if (error !== 'terminal_reader_timeout') {
                    this.toast.danger(error);
                  }
                } else {
                  if (error.message.startsWith('Action canceled')) {
                    this.store.dispatch(new ClearOrder());
                    this.navigation.navigateToKioskStartOrderPage(this.displayOrder.location.locationID);
                    return;
                  }
                  if (this.checkout_wizard === 'payment' && this.activeSection === 'selectPaymentType') {
                    this.goBackward();
                  } else {
                    this.activeSection = 'selectPaymentType';
                  }
                  if (error.code !== 'terminal_reader_timeout') {
                    this.toast.danger(`${error.code} | ${error.message}`);
                  }
                }
              }
            );
          } else {
            this.toast.danger('No terminal connected');
          }
        });
      },
      error => {
        this.toast.danger(this.errorService.checkoutError(error));
      }
    );
  }

  openPayAtCounterModal(content: any) {
    this.modalService.open(content, {
      centered: true,
      animation: true,
      windowClass: 'pay-at-counter-modal',
    });
  }

  kioskPayInStoreSubmit() {
    this.paymentType = PaymentTypes.cash;
    this.paymentTouched = true;
    this.userForm.setValue({
      firstName: sessionStorage.getItem('kioskGuest'),
      lastName: sessionStorage.getItem('kioskTable') ? `Table #: ${sessionStorage.getItem('kioskTable')}` : 'guest',
      emailAddress: sessionStorage.getItem('kioskEmail') || 'guest@dineengine.io',
      phoneNumber: sessionStorage.getItem('kioskPhoneNumber') || '0000000000',
      guestOptIn: false,
      signUpOptIn: false,
      emailOptIn: false,
      smsOptIn: false,
      password: '',
      password2: '',
      birthday: '',
    });
    sessionStorage.setItem('kioskPayment', 'cash');
    this.requestToken();
  }

  continueToConfirmation() {
    this.navigateToConfirmation('1234');
  }

  oloPaySubissionCallback(paymentResponse) {
    if (paymentResponse) {
      // Use your basket submission API call here to send the payment method id as `token`
      try {
        this.paymentMethod = {
          schemeID: 1,
          token: paymentResponse.id,
          cardType: paymentResponse.card.brand,
          cardLastFour: paymentResponse.card.last4,
          streetAddress: paymentResponse.billing_details.address.line1,
          streetAddress2: paymentResponse.billing_details.address.line2,
          zipCode: paymentResponse.billing_details.address.postal_code,
          countryCode: paymentResponse.card.country,
          expirationMonth: paymentResponse.card.exp_month,
          expirationYear: paymentResponse.card.exp_year,
          isDigitalWallet: !!paymentResponse.card?.wallet?.type,
          digitalWalletType: paymentResponse.card?.wallet?.type,
        };
        this.paymentType = PaymentTypes.creditCardToken;
        this.paymentTouched = true;
        this.submit();
        // Signals to the Apple Pay or Google Pay payment sheet that the transaction has been processed
        // successfully and that the payment sheet can be closed
        this.oloPayService.digitalWallets.completePaymentEvent();
      } catch (error) {
        if (error.message) {
          this.errorMessage = error.message;
          this.toast.danger(error.message);
        }
        // Signals to the Apple Pay or Google Pay payment sheet that the payment has failed
        this.oloPayService.digitalWallets.failPaymentEvent();
      }
    }
  }

  kioskEditItemClick(product: OrderItem) {
    let itemCat;
    this.displayMenu.categories.forEach(cat => {
      if (cat.products.find((prod: any) => prod.productID === product.productID)) {
        itemCat = cat.nameSlug ? cat.nameSlug : cat.categoryID;
      }
    });
    const productSlug = product.nameSlug ? product.nameSlug : product.productID;
    if (this.modeService.mode === 'kiosk') {
      // tslint:disable-next-line:max-line-length
      this.store
        .dispatch(
          new SetProduct(this.locationID, itemCat, product.nameSlug ? product.nameSlug : product.productID, this.displayOrder.handoffType)
        )
        .subscribe();
      this.basketProdID = product.orderItemID;
      this.modalService.dismissAll();
      this.modalService
        .open(this.modifyModalRef, {
          centered: true,
          animation: true,
        })
        .closed.subscribe(() => this.clickCart());
    } else {
      this.navigation.navigateToMenuPage(this.locationID, itemCat, productSlug, 'modify', product.productID, product.orderItemID);
    }
  }

  kioskUpdateQuantClicked(updateInfo) {
    // this.cartProductLoading = updateInfo.product.orderItemID;
    if (updateInfo.product.quantity === 1 && updateInfo.quant === -1) {
      this.cartService.removeProduct(updateInfo.product);
    } else {
      this.store.dispatch(new UpdateBasketItem(updateInfo.product, updateInfo.product.quantity + updateInfo.quant));
    }
  }

  kioskRemoveClicked(product: OrderItem) {
    this.cartProductLoading.push(product.orderItemID.toString());
    this.store
      .dispatch(new RemoveFromOrder(product))
      .toPromise()
      .then(() => {
        this.cartProductLoading.splice(this.cartProductLoading.indexOf(product.orderItemID, 1));
      });
  }

  // Rewards Redemption

  async applyStagedReward(reward) {
    this.applyingReward = true;
    const couponCode = this.displayOrder?.appliedCouponCode;
    if (couponCode) {
      await this.store.dispatch(new RemovePromoCode()).toPromise();
    }
    await this.store
      .dispatch(new RedeemReward(reward))
      .toPromise()
      .then(() => {
        if (couponCode) {
          this.store.dispatch(new AddPromoCode(couponCode));
        }
        this.store
          .dispatch(new UpdateAppliedRewards(reward))
          .toPromise()
          .then(newState => this.checkRewardsStatus(newState.order.order.appliedRewards));
      })
      .catch(error => {
        if (couponCode) {
          this.store.dispatch(new AddPromoCode(couponCode));
        }
        this.store
          .dispatch(new UpdateAppliedRewards(reward))
          .toPromise()
          .then(newState => this.checkRewardsStatus(newState.order.order.appliedRewards));
        this.mapAndShowError(error);
      });
  }

  checkRewardsStatus(appliedRewards: Reward[]) {
    this.clearLoadingReward();
    if (appliedRewards && !appliedRewards.length) {
      if (this.modeService.mode === 'desktop') {
        this.checkoutMessage = "Your cart doesn't meet the requirements for this reward.";
      } else {
        this.toast.info("Your cart doesn't meet the requirements for this reward.");
      }
    } else {
      this.toast.success(appliedRewards[0].name + ' applied to basket');
      this.store.dispatch(new StageReward(null));
    }
  }

  clearLoadingReward() {
    this.applyingReward = false;
    this.areRewardsSelecting = false;
    this.loadingReward = null;
  }

  clickCart() {
    document.getElementById('cartButton').click();
  }

  setUserData(formValue: UntypedFormGroup) {
    console.log(formValue);
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  togglePassword2() {
    this.showPassword2 = !this.showPassword2;
  }

  onKey(e, nextElement, goBirth?) {
    if (e.key === 'Enter') {
      if (goBirth) {
        this.birthdayPick.open();
      } else {
        nextElement.setFocus();
      }
    }
  }

  addNewCardClicked() {
    this.paymentType = PaymentTypes.creditCard;
    this.addCardClicked = !this.addCardClicked;
  }

  mapAndShowError(error) {
    this.errorMessage = error instanceof DineEngineError ? error.message : this.errorService.rewardApplyError(error as HttpErrorResponse);
    if (!this.errorMessage.toLowerCase().includes('reward')) {
      this.toast.danger(this.errorMessage);
    }
  }

  mapAddress(specialInst: string) {
    const newAddress: Address = {
      address1: this.streetAddress.value,
      address2: this.suite.value,
      city: this.city.value,
      state: this.state.value,
      zipCode: this.zip.value,
      latitude: this.displayOrder.deliveryAddress.latitude,
      longitude: this.displayOrder.deliveryAddress.latitude,
      specialInstructions: specialInst,
    };
    return newAddress;
  }

  get firstName() {
    return this.userForm.get('firstName');
  }

  get lastName() {
    return this.userForm.get('lastName');
  }

  get emailAddress() {
    return this.userForm.get('emailAddress');
  }

  get phoneNumber() {
    return this.userForm.get('phoneNumber');
  }

  get guestOptIn() {
    return this.userForm.get('guestOptIn');
  }

  get signUpOptIn() {
    return this.userForm.get('signUpOptIn');
  }

  get password() {
    return this.userForm.get('password');
  }

  get password2() {
    return this.userForm.get('password2');
  }

  get birthday() {
    return this.userForm.get('birthday');
  }

  get favoriteLocation() {
    return this.userForm.get('favoriteLocation');
  }

  get specialInstructions() {
    return this.deliveryForm.get('specialInstruction');
  }

  get streetAddress() {
    return this.deliveryForm.get('streetAddress');
  }

  get suite() {
    return this.deliveryForm.get('suite');
  }

  get city() {
    return this.deliveryForm.get('city');
  }

  get state() {
    return this.deliveryForm.get('state');
  }

  get zip() {
    return this.deliveryForm.get('zip');
  }

  get ccName() {
    return this.creditCardForm.get('ccName');
  }

  get ccNumber() {
    return this.creditCardForm.get('ccNumber');
  }

  get ccCVV() {
    return this.creditCardForm.get('ccCVV');
  }

  get ccExpiration() {
    return this.creditCardForm.get('ccExpiration');
  }

  get ccZip() {
    return this.creditCardForm.get('ccZip');
  }

  get ccBillingCountry() {
    return this.creditCardForm.get('ccBillingCountry');
  }

  get ccStreetAddress() {
    return this.creditCardForm.get('ccStreetAddress');
  }

  get ccCity() {
    return this.creditCardForm.get('ccCity');
  }

  get savedCards() {
    return this.savedCreditCardForm.get('savedCards');
  }

  get scCVV() {
    return this.savedCreditCardForm.get('scCVV');
  }

  rewardTrackByFn(index: number, item: Reward) {
    return `${index}${item.rewardID}${item.isApplied}`;
  }

  private setCardFieldProperties(cardNumber: string) {
    const validation = validator.number(cardNumber);

    if (validation.card && this.isValidCardType(validation.card?.type)) {
      this.currentCVVLabel = validation.card.code.name;
      this.currentCVVMask = '0'.repeat(validation.card.code.size);
      // tslint:disable-next-line:max-line-length
      this.currentCardMask = this.generateCardMask(
        '0'
          .repeat(validation.card.lengths.sort((a, b) => a - b)[0])
          .concat(
            '9'.repeat(
              validation.card.lengths.sort((a, b) => a - b)[validation.card.lengths.length - 1] -
                validation.card.lengths.sort((a, b) => a - b)[0]
            )
          ),
        validation.card.gaps
      );
      this.currentCreditCardType = validation.card.type;
    } else {
      this.currentCreditCardType = 'unknown';
      this.currentCVVLabel = 'CVV';
    }
  }

  private generateCardMask(preMask: string, gaps: number[]): string {
    let newMask = preMask;
    gaps
      .sort((a, b) => a - b)
      .reverse()
      .forEach(gap => {
        newMask = newMask.slice(0, gap) + '-' + newMask.slice(gap);
      });
    return newMask;
  }

  private isValidCardType(cardType: string): boolean {
    return cardType === 'visa' || cardType === 'mastercard' || cardType === 'american-express' || cardType === 'discover';
  }

  setBirthdayValue(event: IonDatetimeCustomEvent<{ value: string }>) {
    const fullDateTimeString = event?.detail?.value;

    if (fullDateTimeString) {
      // Parse the original date string
      const dateObject = new Date(fullDateTimeString);

      if (dateObject > this.earliestBDay) {
        this.toast.danger('You must be at least 13 years old to create an account.');
        return;
      }

      // Extract date components
      const year = dateObject.getFullYear();
      const month = ('0' + (dateObject.getMonth() + 1)).slice(-2); // Adding 1 because months are zero-indexed
      const day = ('0' + dateObject.getDate()).slice(-2);

      // Format the date as MM-DD-YYYY
      const reformattedDate = `${month}/${day}/${year}`;

      // Set the reformatted date to your form control or variable
      this.birthday.setValue(reformattedDate);

      // Log the modified value
      console.log(fullDateTimeString);
      console.log(this.birthday.value);
    } else {
      console.error('Invalid or undefined date value');
    }
  }

  public pickerButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Confirm',
      handler: value => {
        console.log(`You selected:`, value);
        this.favoriteLocation.setValue(value.location.value);
      },
    },
  ];

  @ViewChild('firstNameInput') firstNameInput!: ElementRef;
  @ViewChild('tableNumberInput') tableNumberInput!: ElementRef;
  @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef;
  @ViewChild('emailAddressInput') emailAddressInput: ElementRef;

  checkout_wizard = 'nameEntry';
  pin = '';

  creditCardAnimationOptions: AnimationOptions = {
    path: 'assets/lottie/card-tap.json',
  };

  payAtCounterAnimationOptions: AnimationOptions = {
    path: 'assets/lottie/pay-at-register.json',
  };

  // ngAfterViewInit() {
  //   this.setFocus();
  // }

  // setFocus() {
  //   setTimeout(() => {
  //     if (this.checkout_wizard === 'nameEntry' && this.firstNameInput) {
  //       this.firstNameInput.nativeElement.focus({ preventScroll: true });
  //     } else if (this.checkout_wizard === 'tableNumberEntry' && this.tableNumberInput) {
  //       this.tableNumberInput.nativeElement.focus({ preventScroll: true });
  //     }
  //   }, 0);
  // }

  goForward() {
    if (this.checkout_wizard === 'nameEntry' && this.nameForm.valid) {
      const nameProvided: string = this.nameForm.get('guestName')?.value;
      const phoneNumberProvided: string = this.nameForm.get('phoneNumber')?.value;
      const emailProvided: string = this.nameForm.get('emailAddress')?.value || 'guest@dineengine.io';

      sessionStorage.setItem('kioskGuest', nameProvided);
      sessionStorage.setItem('kioskPhoneNumber', phoneNumberProvided);
      sessionStorage.setItem('kioskEmail', emailProvided);

      this.mainSettings$.subscribe(ms => {
        if (ms.allow_manual_tablenumber_entry) {
          this.checkout_wizard = 'tableNumberEntry';
        } else if (this.displayOrder?.canTip) {
          this.checkout_wizard = 'tipEntry';
        } else {
          this.checkout_wizard = 'payment';
        }
      });
    } else if (this.checkout_wizard === 'tableNumberEntry' && this.tableNumberForm.valid) {
      const numberProvided: string = this.tableNumberForm.get('tableNumber')?.value;
      sessionStorage.setItem('kioskTable', numberProvided);
      sessionStorage.setItem('tableNumber', numberProvided);
      if (this.displayOrder.canTip) {
        this.checkout_wizard = 'tipEntry';
      } else {
        this.checkout_wizard = 'payment';
        if (this.activeSection === this.kioskPaymentTypes.prepaid) {
          this.openPrepaidSection();
        }
      }
    } else if (this.checkout_wizard === 'tipEntry') {
      // Logic for handling the tip entry form validation and storage if needed
      // Assuming you have a form named tipForm for capturing tip information
      if (this.tipForm.valid) {
        const tipAmount: number = this.tipForm.get('tipAmount')?.value;
        sessionStorage.setItem('kioskTipAmount', tipAmount?.toString());
        this.checkout_wizard = 'payment';
        if (this.activeSection === this.kioskPaymentTypes.prepaid) {
          this.openPrepaidSection();
        }
      }
    }
  }

  goBackward() {
    if (this.checkout_wizard === 'payment') {
      this.cardTerminal.getService().subscribe(terminal => {
        if (terminal) {
          terminal.cancelTransaction().subscribe();
        }
      });
      if (this.displayOrder?.canTip) {
        this.checkout_wizard = 'tipEntry';
      } else {
        this.mainSettings$.subscribe(ms => {
          if (ms.allow_manual_tablenumber_entry) {
            this.checkout_wizard = 'tableNumberEntry';
          } else {
            this.checkout_wizard = 'nameEntry';
          }
        });
      }
    } else if (this.checkout_wizard === 'tipEntry') {
      this.mainSettings$.subscribe(ms => {
        if (ms.allow_manual_tablenumber_entry) {
          this.checkout_wizard = 'tableNumberEntry';
        } else {
          this.checkout_wizard = 'nameEntry';
        }
      });
    } else if (this.checkout_wizard === 'tableNumberEntry') {
      this.checkout_wizard = 'nameEntry';
    }
  }

  appendNumber(number: number) {
    this.pin += number.toString();
  }

  clearInput() {
    this.pin = '';
  }

  backspace() {
    this.pin = this.pin.slice(0, -1);
  }

  gotoNextField(nextElement: HTMLInputElement) {
    nextElement.focus();
  }

  startOverClicked() {
    this.modalService
      .open(KioskStartOverModalComponent, {
        size: 'lg',
        ariaLabelledBy: 'startOverModal',
        centered: true,
        windowClass: 'kiosk-modal',
      })
      .result.then(
        result => {
          console.log(`Closed with: ${result}`);
        },
        reason => {
          console.log(`Closed with: ${reason}`);
        }
      );
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.value as string;
      const errors: ValidationErrors = {};

      if (!password) {
        return null; // if no password, no need to validate
      }
      // console.log(password.length);
      // Check for minimum length
      if (password.length < 10) {
        errors['minLength'] = 'Password must be at least 10 characters long';
      }

      // console.log(control.value);
      // Check for uppercase letter
      const uppercasePattern = /[A-Z]/;
      // console.log(uppercasePattern.test(password));
      if (!uppercasePattern.test(password)) {
        errors['uppercase'] = 'Password must contain at least one uppercase letter';
      }

      // Check for special character
      const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
      // console.log(specialCharPattern.test(password));
      if (!specialCharPattern.test(password)) {
        errors['specialChar'] = 'Password must contain at least one special character';
      }

      // If no errors, return null, otherwise return the errors object
      return Object.keys(errors).length > 0 ? errors : null;
    };
  }
}
